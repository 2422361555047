import CalendarComponent from "../Components/CalendarComponent";
import { ClientSideSuspense } from "@liveblocks/react";
import { RoomProvider } from "@liveblocks/react";
import LiveblocksProviderWrapper from "../Components/LiveblocksProviderWrapper";
import { GoogleOAuthProvider } from "@react-oauth/google";

import "./Calendar.css";

function App() {
  return (
    <div>
      <GoogleOAuthProvider clientId="323150633316-m43g3oj3qkcvlkookie1qji41o2oat0b.apps.googleusercontent.com">
        <LiveblocksProviderWrapper>
          {/* Pass the authEndpoint directly */}
          <RoomProvider
            id="motion-air-calendar" // Ensure this is a valid room ID
            initialPresence={{ cursor: null }}
          >
            <ClientSideSuspense fallback={<div>Loading...</div>}>
              {() => <CalendarComponent />}
            </ClientSideSuspense>
          </RoomProvider>
        </LiveblocksProviderWrapper>
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;
