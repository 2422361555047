import React, { useContext, useEffect } from 'react';
import { Eventcalendar, Popup, toast, localeNo, confirm } from "@mobiscroll/react";
import { auth, onAuthStateChanged, query, collection, database, where, getDocs, sendPasswordResetEmail } from '../Utils/firebaseConfig';

import { resources } from "../Utils/resources"
import './Agenda.css'
import "@mobiscroll/react/dist/css/mobiscroll.react.min.css";

import { useAuth } from '../Hooks/useAuth';



const Agenda = () => {

  const { user, userSettings } = useAuth()
  //CUSTOM CODE
  const eventDatabase = process.env.REACT_APP_SERVER_LOCATION

  const [myEvents, setEvents] = React.useState([]);
  const [isChecked, setIsChecked] = React.useState([]);
  const [eventContent, setEventContent] = React.useState([]);

  const confirmReset = React.useRef();
  const [isOpen, setOpen] = React.useState(false);
  const [anchor, setAnchor] = React.useState(null);

  // STANDARD:
  const [currentEvent, setCurrentEvent] = React.useState(null);
  const [equipment, setEquipment] = React.useState([]);
  // const [time, setTime] = React.useState('');
  // const [status, setStatus] = React.useState('');
  // const [reason, setReason] = React.useState('');
  // const [location, setLocation] = React.useState('');
  // const [buttonText, setButtonText] = React.useState('');
  // const [buttonType, setButtonType] = React.useState('');
  // const [bgColor, setBgColor] = React.useState('');
  const timerRef = React.useRef(null);

  // CUSTOM CODE



  const onPopupClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const openPopup = () => {
    setOpen(true)
  }
  const resetPassword = () => {
    console.log(user.email)
    sendPasswordResetEmail(auth, user.email)
      .then(() => {
        toast({
          message: `mail sent to ${user.email}`
        });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
      });
    setOpen(false);
  }


  function shadeColor(color, percent) {
    var R = parseInt(color.substring(1,3),16);
    var G = parseInt(color.substring(3,5),16);
    var B = parseInt(color.substring(5,7),16);

    R = parseInt(R * (100 + percent) / 100);
    G = parseInt(G * (100 + percent) / 100);
    B = parseInt(B * (100 + percent) / 100);

    R = (R<255)?R:255;  
    G = (G<255)?G:255;  
    B = (B<255)?B:255;  

    var RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
    var GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
    var BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));

    return "#"+RR+GG+BB;
}


  React.useEffect(() => {

    const handleResourceCheckInEvent = (email) => {
      flattenArr(resources).map((item, index) => {
        // console.log(item.email)
        if (item.email === email) {
          getUserEvents(item.id);
        };
      });
    };

    const getUserEvents = async (id) => {
      const userRef = collection(database, eventDatabase);
      const q = query(userRef, where('resource', 'array-contains', id));
      const querySnapshot = await getDocs(q);
      let events = [];
      querySnapshot.forEach((doc) => {
        events.push({
          ...doc.data(),
          start: doc.data().start.toDate(),
          end: doc.data().end.toDate()
        });
      });
      setEvents(events);
    }

    handleResourceCheckInEvent(user.email)


  }, []);




  function flattenArr(arr, flat = []) {
    for (let i = 0; i < arr.length; i++) {
      flat.push(arr[i]);
      if (arr[i].children && arr[i].children.length) {
        flat.pop() // remove headers from list
        flattenArr(arr[i].children, flat);
      }
    }
    return flat;
  }




  const handleResourceCheckInEvent = (resource) => {
    if (!isChecked.includes(resource.id)) {
      setIsChecked([...isChecked, resource.id]);
    } else {
      const index = isChecked.indexOf(resource.id)
      const test = isChecked
      if (index > -1) { // only splice array when item is found
        test.splice(index, 1); // 2nd parameter means remove one item only
      }
      setIsChecked([...test])
    }
  }


  // FIKS DETTE HER!!!
  const renderElement = () => {
    console.log(typeof eventContent)
    console.log(eventContent)
    eventContent.map(item => {
      console.log(item.name)
      return (<div>{item.name}</div>)
    })
  }

  const onEventClick = React.useCallback((args) => {
    const event = args.event;



    const resourcesInEvent = []
    args.event.resource.forEach(element => flattenArr(resources).filter((obj) => {
      if (obj.id == element) {
        resourcesInEvent.push(obj)
      }

    }))
    resourcesInEvent.sort(function (a, b) {
      return (a.id - b.id) || a.name.localeCompare(b.name);
    });
    setEventContent([...resourcesInEvent])


    setCurrentEvent(event);


    setIsChecked(args.event.resource)
    setEquipment([args.event.resource])
    setAnchor(args.domEvent.target);
    // setOpen(true);
  }, []);

  const onEventHoverOut = React.useCallback(() => {
    timerRef.current = setTimeout(() => { setOpen(false); }, 200);
  }, []);

  // const onEventClick = React.useCallback(() => {
  //   setOpen(true);
  // }, []);

  // const onMouseEnter = React.useCallback(() => {
  //   if (timerRef.current) {
  //     clearTimeout(timerRef.current);
  //   }
  // }, []);

  const onMouseLeave = React.useCallback(() => {
    timerRef.current = setTimeout(() => { setOpen(false); }, 200);
  }, []);

  const popupButtons = React.useMemo(() => {
    return ['cancel', {
      handler: () => {
        setOpen(false)
      },
      keyCode: 'enter',
      text: 'Add',
      cssClass: 'mbsc-popup-button-primary'
    }];

  }, []);

  const onClose = React.useCallback(() => {

    // refresh the list, if add popup was canceled, to remove the temporary event
    setOpen(false);
  }, []);




  const view = React.useMemo(() => {
    return {
      agenda: { type: 'week' }
    };
  }, []);



  return (
    <div className='main'>
      <div className='flex-container'>
        <Eventcalendar
          className='agenda-calendar'
          theme="ios"
          themeVariant="light"
          locale={localeNo}
          height={'calc(100vh - 200px)'}
          data={myEvents}
          view={view}
          clickToCreate={false}
          dragToCreate={false}
          showEventTooltip={false}
          onEventClick={onEventClick}
        // onEventHoverIn={onEventHoverIn}
        // onEventHoverOut={onEventHoverOut}
        />
        {/* <Popup
          display="anchored"
          isOpen={isOpen}
          anchor={anchor}
          touchUi={false}
          showOverlay={false}
          contentPadding={false}
          closeOnOverlayClick={false}
          width={350}
          cssClass="md-tooltip"
          buttons={popupButtons}
          onClose={onClose}
        >
          <div className="mbsc-form-group">
            <div className="md-tooltip-header" style={{ backgroundColor: bgColor }}>
              <span className="md-tooltip-name-title"></span>
            </div>
            <div className="md-tooltip-info">
              <div className="md-tooltip-title">
              </div>
              <div className="md-tooltip-title">
                {array.map((item, index) => (
                  <div>
                    {console.log(item)}
                    <Checkbox disabled={true} label={item.name} defaultChecked={isChecked.includes(item.id)} onChange={() => handleResourceCheckInEvent(item)}></Checkbox>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Popup> */}
        <div className='agenda-content'>
          <div className='header'>
            <h2>User Settings</h2>
          </div>
          <div className='user-header'>
            <div className='user-content'>
              <h3>{user.displayName}</h3>
              <p>{user.email}</p>
            </div>
            <img src={userSettings.userImage} alt="" />
          </div>
          <div>
            {equipment.map((item, index) => (
              <div>
                <div >{item.name}</div>
              </div>
            ))}
            <div className='selected-event-container'>
              {/* <h2>{currentEvent === null ? currentEvent : console.log('  no active event')}</h2> */}
              <h2 style={{ backgroundColor: currentEvent?.color }} className='selected-event-header'>{currentEvent?.title ?? 'Select an event'}</h2>
              <p style={{ backgroundColor: currentEvent?.color }} className='selected-event-desctiption'>{currentEvent?.description ?? ''}</p>
              <div className="mbsc-form-group">
                <h4>{currentEvent ? 'Crew' : ''}</h4>
                {eventContent.map(item => {
                  if (item.id < 5) {
                    return (<div className='selected-event-crew' key={item.id}>{item.name}</div>)
                  }
                })}
              </div>
              <div className="mbsc-form-group">
                <h4>{currentEvent ? 'Equipment' : ''}</h4>
                {eventContent.map(item => {
                  if (item.id > 5) {
                    return (<div className='selected-event-equipment' key={item.id}>{item.name}</div>)
                  }
                })}
              </div>
            </div>
          </div>
        </div>
        <Popup
          display="bottom"
          contentPadding={false}
          showArrow={false}
          showOverlay={false}
          anchor={anchor}
          isOpen={isOpen}
          width={'100%'}
          // buttons={colorButtons}
          // responsive={colorPopup}
          ref={confirmReset}
          onClose={onPopupClose}
        >
          <div className='confirm-dialog'>
            <h2 onClick={resetPassword}>Reset</h2>
          </div>
        </Popup>
      </div>
      <div className='footer'>
        <div className='reset-pwd-btn' onClick={openPopup} >Reset password</div>
      </div>
    </div>
  );


}

export default Agenda