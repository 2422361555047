import { initializeApp } from "firebase/app";

import { getAuth, connectAuthEmulator, signInWithRedirect, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, getRedirectResult, onAuthStateChanged, signOut, sendPasswordResetEmail, deleteUser } from "firebase/auth";

import { getFirestore, connectFirestoreEmulator, collection, getDocs, doc, addDoc, query, where, updateDoc, deleteDoc, onSnapshot, orderBy, getDoc, setDoc, arrayUnion, Timestamp } from "firebase/firestore";

import { getDatabase, onValue, ref, connectDatabaseEmulator, set, push, get, child, update } from "firebase/database";

import { getFunctions, connectFunctionsEmulator, httpsCallable } from 'firebase/functions';

import { getStorage, connectStorageEmulator, ref as storageRef, uploadBytes, getDownloadURL, uploadBytesResumable } from "firebase/storage";



const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID ,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const auth = getAuth(firebase)
const database = getFirestore(firebase);
const realtimeDb = getDatabase(firebase);
const functions = getFunctions(firebase)
const storage = getStorage(firebase);


if (window.location.hostname.includes("localhost")) {
  // Point to the RTDB emulator running on localhost.
  connectDatabaseEmulator(realtimeDb, "localhost", 9000);
  connectFirestoreEmulator(database, 'localhost', 8080);
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFunctionsEmulator(functions, "localhost", 5001);
  connectStorageEmulator(storage, "localhost", 9199);

} 



export { 
  firebase, auth, database, ref, onValue, realtimeDb, set, push, get, child, update, deleteUser,

  signInWithRedirect, GoogleAuthProvider, getRedirectResult, onAuthStateChanged, signOut, signInWithEmailAndPassword, sendPasswordResetEmail, signInWithPopup,

  collection, getDocs, doc, addDoc, query, where, updateDoc, deleteDoc, onSnapshot, orderBy, getDoc, setDoc, arrayUnion, Timestamp,

  functions, getFunctions, connectFunctionsEmulator, httpsCallable,


  storageRef, uploadBytes, storage, getDownloadURL, uploadBytesResumable
}