import { FieldPath } from "firebase/firestore";
import {
  database,
  realtimeDb,
  ref,
  onValue,
  collection,
  getDocs,
  doc,
  addDoc,
  query,
  where,
  updateDoc,
  deleteDoc,
} from "./firebaseConfig";

const fetchEvents = async (eventDatabase) => {
  const querySnapshot = await getDocs(collection(database, eventDatabase));
  let allEvents = [];
  querySnapshot.forEach((doc) => {
    allEvents.push({
      ...doc.data(),
      start: doc.data().start.toDate(),
      end: doc.data().end.toDate(),
    });
  });
  return allEvents;
};

const addEvents = async (
  eventDatabase,
  id,
  title,
  description,
  start,
  end,
  allDay,
  status,
  color,
  resource,
  editable,
  busy,
  createdAt,
  visibleInCalendar,
  subprojectStatus
) => {


  try {
    await addDoc(collection(database, eventDatabase), {
      id: id,
      title: title,
      description: description,
      start: start,
      end: end,
      allDay: allDay,
      status: status,
      color: color,
      resource: resource,
      editable: editable,
      busy: busy,
      createdAt: createdAt,
      visibleInCalendar: visibleInCalendar || null,
      subprojectStatus: subprojectStatus || null,
    });
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};

const updateEvent = async (
  eventDatabase,
  id,
  title,
  description,
  start,
  end,
  allDay,
  status,
  color,
  resource,
  editable,
  busy,
  updatedAtTimestamp
) => {
  const userRef = collection(database, eventDatabase);
  const q = query(userRef, where("id", "==", `${id}`));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    updateDoc(doc.ref, {
      id: id,
      title: title,
      description: description,
      start: start,
      end: end,
      allDay: allDay,
      status: status,
      color: color,
      resource: resource,
      editable: editable,
      busy: busy,
      updatedAt: updatedAtTimestamp,
    });
  });
};

const deleteEvent = async (eventDatabase, event) => {
  const userRef = collection(database, eventDatabase);
  const q = query(userRef, where("id", "==", `${event.id}`));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((item) => {
    try {
      deleteDoc(doc(database, eventDatabase, item.ref.id));
      console.log(`deleted post with id: ${item.ref.id}`);
    } catch (ex) {
      console.error(`Delete FAILED: ${ex.message}`);
      throw ex;
    }
  });
};

const restoreEvent = async (eventDatabase, event) => {
  try {
    await addDoc(collection(database, eventDatabase), {
      ...event,
    });
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};

// USER SETTINGS CRUD

const addUserSettings = async (settingsDatabase, id, title) => {
  try {
    await addDoc(collection(database, settingsDatabase), {
      id: id,
      title: title,
    });
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};

const updateUserSetting = async (
  settingsDatabase,
  userEmail,
  settingToChange,
  newSettingValue
) => {
  // console.log(
  //   // "collectionId:",
  //   // collectionId,
  //   "userEmail:",
  //   userEmail,
  //   "settingToChange:",
  //   settingToChange,
  //   "newSettingValue:",
  //   newSettingValue,

  // );
  const userRef = collection(database, settingsDatabase);
  const q = query(userRef, where("userEmail", "==", `${userEmail}`));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    updateDoc(doc.ref, {
      ...doc.data(),
      [settingToChange]: newSettingValue,
    });
  });
};

const editUserSettingXXX = async (settingsDatabase, id, settingName) => {
  const userRef = collection(database, settingsDatabase);
  const q = query(userRef, where("id", "==", `${id}`));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    updateDoc(doc.ref, {
      settingToChange: settingName,
    });
  });
};

// TODO CRUD

const addTodoList = async (eventDatabase, id, title) => {
  try {
    await addDoc(collection(database, eventDatabase), {
      id: id,
      title: title,
    });
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};

// const setListEdit = async (todoDatabase, id, editing) => {
//   const userRef = collection(database, todoDatabase);
//   const q = query(userRef, where('id', '==', `${id}`));
//   const querySnapshot = await getDocs(q);
//   querySnapshot.forEach((doc) => {
//     updateDoc(doc.ref, {
//       editing: editing
//     })
//   })
// }

const updateList = async (todoDatabase, id, title) => {
  const userRef = collection(database, todoDatabase);
  const q = query(userRef, where("id", "==", `${id}`));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    updateDoc(doc.ref, {
      title: title,
    });
  });
};

const updateCurrentList = async (todoDatabase, id, current) => {
  const userRef = collection(database, todoDatabase);
  const q = query(userRef, where("id", "==", `${id}`));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    updateDoc(doc.ref, {
      current: current,
    });
  });
};

const addTodo = async (
  eventDatabase,
  collectionId,
  id,
  title,
  desc,
  checked,
  editing,
  order
) => {
  try {
    await addDoc(collection(database, eventDatabase, collectionId, "todos"), {
      id: id,
      title: title,
      desc: desc,
      checked: checked,
      order: order,
    });
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};

const updateTodo = async (
  eventDatabase,
  collectionId,
  id,
  title,
  description,
  checked,
  order
) => {
  // console.log(
  //   "collectionId:",
  //   collectionId,
  //   "Id:",
  //   id,
  //   "title:",
  //   title,
  //   "desc",
  //   description,
  //   "Checked",
  //   checked
  // );
  const userRef = collection(database, eventDatabase, collectionId, "todos");
  const q = query(userRef, where("id", "==", `${id}`));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    updateDoc(doc.ref, {
      id: id,
      title: title,
      desc: description,
      checked: checked,
      order: order,
    });
  });
};

const reorderTodo = async (eventDatabase, collectionId, id, order) => {
  // console.log(
  //   "collectionId:",
  //   collectionId,
  //   "order:",
  //   order,
  // );
  const userRef = collection(database, eventDatabase, collectionId, "todos");
  const q = query(userRef, where("id", "==", `${id}`));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    updateDoc(doc.ref, {
      ...doc.data(),
      order: order,
    });
  });
};

// const deleteList = async (eventDatabase, collectionId, todo) => {
//   const userRef = collection(database, eventDatabase, collectionId);
//   const q = query(userRef);
//   const querySnapshot = await getDocs(q);
//   querySnapshot.forEach((item) => {
//     try {
//       deleteDoc(doc(database, collectionId, item.ref.id))
//       console.log(`deleted todo with id: ${item.ref.id}`)
//     } catch (ex) {
//       console.error(`Delete FAILED: ${ex.message}`);
//       throw ex;
//     }
//   })
// };

const deleteList = async (eventDatabase, collectionId, listId) => {
  const userRef = collection(database, eventDatabase);
  const q = query(userRef, where("id", "==", listId));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((item) => {
    deleteAllTodos("todos", item.ref.id);
    try {
      deleteDoc(doc(database, eventDatabase, item.ref.id));
      console.log(`deleted todo with id: ${item.ref.id}`);
    } catch (ex) {
      console.error(`Delete FAILED: ${ex.message}`);
      throw ex;
    }
  });
};

const deleteAllTodos = async (eventDatabase, listId) => {
  const userRef = collection(database, eventDatabase, listId, "todos");
  const querySnapshot = await getDocs(userRef);
  querySnapshot.forEach((item) => {
    try {
      deleteDoc(doc(database, eventDatabase, listId, "todos", item.ref.id));
      console.log(`deleted todo with id: ${item.ref.id}`);
    } catch (ex) {
      console.error(`Delete FAILED: ${ex.message}`);
      throw ex;
    }
  });
};

const deleteTodo = async (eventDatabase, collectionId, todoId) => {
  const userRef = collection(database, eventDatabase, collectionId, "todos");
  const q = query(userRef, where("id", "==", todoId));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((item) => {
    try {
      deleteDoc(
        doc(database, eventDatabase, collectionId, "todos", item.ref.id)
      );
      console.log(`deleted todo with id: ${item.ref.id}`);
    } catch (ex) {
      console.error(`Delete FAILED: ${ex.message}`);
      throw ex;
    }
  });
};

export {
  fetchEvents,
  updateEvent,
  addEvents,
  deleteEvent,
  restoreEvent,
  updateUserSetting,
  addTodoList,
  addTodo,
  updateTodo,
  deleteTodo,
  deleteList,
  updateList,
  updateCurrentList,
  reorderTodo,
};
