import React, { useContext } from 'react';
import { storageRef, uploadBytes, storage, getDownloadURL, uploadBytesResumable } from '../../Utils/firebaseConfig';
import { updateUserSetting } from '../../Utils/firebaseCRUD';

import { Popup, Button } from '@mobiscroll/react';
import AvatarEditor from 'react-avatar-editor'


import Sidebar from './Sidebar';


import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LockIcon from '@mui/icons-material/Lock';
import PersonIcon from '@mui/icons-material/Person';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import SettingsIcon from '@mui/icons-material/Settings';
import CheckIcon from '@mui/icons-material/Check';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import IconButton from '@mui/material/IconButton';
import { Edit } from '@mui/icons-material';
import Slider from '@mui/material/Slider';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';



import './Settings.css'

import { useAuth } from '../../Hooks/useAuth';
import Colorful from '@uiw/react-color-colorful';
import CalendarIntegration from '../../Utils/CalendarIntegration';



const responsivePopup = {
  medium: {
    display: 'center',
    width: 400,
    fullScreen: false,
    touchUi: false,
    showOverlay: false
  }
};

const Settings = () => {

  const { user, userSettings } = useAuth()

  const settingsDatabase = "user-settings"
  const drawerWidth = 240;

  const [pageContent, setPageContent] = React.useState('userContent')
  const [colorInput, setColorInput] = React.useState('#ffffff')
  const [borderInput, setBorderInput] = React.useState('#444')
  const [userImage, setUserImage] = React.useState('')
  const [imageZoomLevel, setImageZoomLevel] = React.useState(1)

  const [isHover, setIsHover] = React.useState(false)
  const [imageUpload, setImageUpload] = React.useState(null)
  const inputFile = React.useRef(null)
  const editor = React.useRef(null);

  // GENERAL SETTINGS
  const [showProfilePictureInAvatar, setShowProfilePictureInAvatar] = React.useState(false)
  const [showProfilePictureInCursor, setShowProfilePictureInCursor] = React.useState(false)
  const [startPage, setStartPage] = React.useState(20)

  //POPUP
  const [isOpen, setOpen] = React.useState(false);
  const [headerText, setHeader] = React.useState('');


  function shadeColor(color, percent) {
    var R = parseInt(color.substring(1, 3), 16);
    var G = parseInt(color.substring(3, 5), 16);
    var B = parseInt(color.substring(5, 7), 16);

    R = parseInt(R * (100 + percent) / 100);
    G = parseInt(G * (100 + percent) / 100);
    B = parseInt(B * (100 + percent) / 100);

    R = (R < 255) ? R : 255;
    G = (G < 255) ? G : 255;
    B = (B < 255) ? B : 255;

    var RR = ((R.toString(16).length == 1) ? "0" + R.toString(16) : R.toString(16));
    var GG = ((G.toString(16).length == 1) ? "0" + G.toString(16) : G.toString(16));
    var BB = ((B.toString(16).length == 1) ? "0" + B.toString(16) : B.toString(16));

    return "#" + RR + GG + BB;
  }


  React.useEffect(() => {
    if (userSettings["cursor-color"]) {
      setColorInput(userSettings["cursor-color"].dim)
      setBorderInput(userSettings["cursor-color"].color)
    }
    if (userSettings.userImage) {
      setUserImage(userSettings.userImage)
    }
    if (userSettings.showProfilePictureInAvatar) {
      setShowProfilePictureInAvatar(userSettings.showProfilePictureInAvatar)
    }
    if (userSettings.showProfilePictureInCursor) {
      setShowProfilePictureInCursor(userSettings.showProfilePictureInCursor)
    }
    if (userSettings.showProfilePictureInCursor) {
      setShowProfilePictureInCursor(userSettings.showProfilePictureInCursor)
    }
  }, [userSettings]);



  const saveColor = (e) => {
    // const colorInput = colorRef;
    const colorArr = {
      color: borderInput,
      dim: colorInput
    }
    console.log("Color saved: ", colorArr)
    updateUserSetting(settingsDatabase, user.email, "cursor-color", colorArr)
  }


  const convertHexToRGBA = (hexCode, opacity = 1) => {
    let hex = hexCode.replace('#', '');

    if (hex.length === 3) {
      hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
    }
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    /* Backward compatibility for whole number based opacity values. */
    if (opacity > 1 && opacity <= 100) {
      opacity = opacity / 100;
    }

    return `rgba(${r},${g},${b},${opacity})`;
  };


  const handleMouseEnter = (e) => {
    setIsHover(true)
  }


  const handleMouseLeave = (e) => {
    setIsHover(false)
  }


  const getImageUrl = async () => {
    const dataUrl = editor.current.getImage().toDataURL()
    const result = await fetch(dataUrl)
    const blob = await result.blob()
    return blob
  }


  async function uploadImage() {
    const imageURL = await getImageUrl()
    console.log(editor.current.props.image.name)
    setOpen(false);
    const ref = storageRef(storage, editor.current.props.image.name);

    uploadBytes(ref, imageURL).then((snapshot) => {
      console.log('Image uploaded');
      console.log(snapshot)
      getDownloadURL(snapshot.ref).then((url) => {
        updateUserSetting(settingsDatabase, user.email, "userImage", url)

        setUserImage(url)
      });
    });
  }


  const editProfilePic = (e) => {
    setOpen(true)
    setImageUpload(e.target.files[0]);
  };


  const popupButtons = React.useMemo(() => {
    return ['cancel', {
      handler: () => {
        uploadImage();
      },
      keyCode: 'enter',
      text: 'Add',
      cssClass: 'mbsc-popup-button-primary'
    }];

  }, []);


  const onClose = React.useCallback(() => {
    setOpen(false);
  }, []);


  const handleZoomChange = (e, newValue) => {
    if (typeof newValue === 'number') {
      console.log(newValue);
      setImageZoomLevel(newValue);
    }
  }



  // ---------------------------- SETTINGS CHANGE HANDLERS ----------------------------

  // GENERAL SETTINGS
  const handleShowProfilePictureInAvatarChange = (event) => {
    setShowProfilePictureInAvatar(event.target.checked)
    updateUserSetting(settingsDatabase, user.email, 'showProfilePictureInAvatar', event.target.checked)
  }

  const handleShowProfilePictureInCursorChange = (event) => {
    setShowProfilePictureInCursor(event.target.checked)
    updateUserSetting(settingsDatabase, user.email, 'showProfilePictureInCursor', event.target.checked)
  }

  const handleStartPage = (event) => {
    if (event.target.value === 10) {
      setStartPage(10)
      updateUserSetting(settingsDatabase, user.email, 'startPage', 10)
    }
    if (event.target.value === 20) {
      setStartPage(20)
      updateUserSetting(settingsDatabase, user.email, 'startPage', 20)
    }
    if (event.target.value === 30) {
      setStartPage(30)
      updateUserSetting(settingsDatabase, user.email, 'startPage', 30)
    }
    if (event.target.value === 40) {
      setStartPage(40)
      updateUserSetting(settingsDatabase, user.email, 'startPage', 40)
    }
  }

  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };


  return (
    <div className='settings-content'>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', top: "inherit" },
        }}
      >

        <Box sx={{ overflow: 'auto' }}>
          <List>
            <ListItem
              onClick={() => setPageContent('userContent')}
              key={'email'}
              disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary={'User'} />
              </ListItemButton>
            </ListItem>
            <ListItem
              onClick={() => setPageContent('calendarSettings')}
              key={'calendar'}
              disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <CalendarMonthIcon />
                </ListItemIcon>
                <ListItemText primary={'Calendar'} />
              </ListItemButton>
            </ListItem>
            <ListItem
              onClick={() => setPageContent('generalContent')}
              key={'general'}
              disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary={'General'} />
              </ListItemButton>
            </ListItem>
            <ListItem
              onClick={() => setPageContent('colorContent')}
              key={'golor'}
              disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <ColorLensIcon />
                </ListItemIcon>
                <ListItemText primary={'Color'} />
              </ListItemButton>
            </ListItem>
            <ListItem
              onClick={() => setPageContent('passwordContent')}
              key={'password'}
              disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <LockIcon />
                </ListItemIcon>
                <ListItemText primary={'Password'} />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
        </Box>
      </Drawer>



      {/* ---------------------------- USER CONTENT ----------------------------  */}

      {(pageContent === 'userContent') ?
        <div style={{ paddingLeft: "2rem" }}>
          <div className='settings-header'>
            <h2>User Settings</h2>
          </div>
          <div className='settings-content-header'>
            <div className='settings-content-content'>
              <h3>{user.displayName}</h3>
              <p>{user.email}</p>
            </div>

            <div
              className='settings-user-image-container'
              onMouseOver={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >

              <input
                accept="image/*"
                className={'input'}
                style={{ display: 'none' }}
                id="raised-button-file"
                ref={inputFile}
                multiple
                type="file"
                onChange={editProfilePic}
              />

              {
                isHover
                  ? <IconButton
                    size='Large'
                    className='settings-user-image-btn-edit'
                    onClick={() => inputFile.current.click()}
                    htmlFor='file'
                  >
                    <Edit fontSize='large' />

                  </IconButton>
                  : <></>}
              <img
                src={userImage ? userImage : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'} alt="Person" />
            </div>
          </div>
          <Popup
            display="bottom"
            fullScreen={true}
            contentPadding={false}
            headerText={headerText}
            buttons={popupButtons}
            isOpen={isOpen}
            onClose={onClose}
            responsive={responsivePopup}
            cssClass="employee-shifts-popup"
          >
            <div className="mbsc-form-group image-cropper">
              <AvatarEditor
                ref={editor}
                image={imageUpload}
                width={250}
                height={250}
                border={50}
                color={[255, 255, 255, 0.6]} // RGBA
                scale={imageZoomLevel}
                rotate={0}
              />
              <Slider
                size="small"
                value={imageZoomLevel}
                aria-label="Small"
                valueLabelDisplay="auto"
                min={1}
                max={5}
                step={0.01}
                onChange={handleZoomChange}
              />
            </div>
          </Popup>
        </div >

        : <></>
      }
      {/* ---------------------------- CALENDAR SETTINGS ----------------------------  */}

      {(pageContent === 'calendarSettings') ?
        <div style={{ paddingLeft: "2rem" }}>
          <div className='settings-header'>
            <h2>Calendar Settings</h2>
          </div>
          <div className='settings-content-header'>
            <div className='settings-content-content'>
              {/* <h3>{user.displayName}</h3>
              <p>{user.email}</p> */}
            </div>

            {/* <div
              className='settings-user-image-container'
              onMouseOver={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >

              <input
                accept="image/*"
                className={'input'}
                style={{ display: 'none' }}
                id="raised-button-file"
                ref={inputFile}
                multiple
                type="file"
                onChange={editProfilePic}
              />

              {
                isHover
                  ? <IconButton
                    size='Large'
                    className='settings-user-image-btn-edit'
                    onClick={() => inputFile.current.click()}
                    htmlFor='file'
                  >
                    <Edit fontSize='large' />

                  </IconButton>
                  : <></>}
              <img
                src={userImage ? userImage : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'} alt="Person" />
            </div> */}
          </div>
              <CalendarIntegration></CalendarIntegration>
        </div >

        : <></>
      }



      {/* ---------------------------- GENERAL CONTENT ----------------------------  */}

      {(pageContent === 'generalContent') ?
        <div style={{ paddingLeft: "2rem" }}>
          <div className='settings-header'>
            <h2>General Settings</h2>
          </div>
          <div className='settings-content-header'>
            {/* <div className='settings-content-content'>
              <h3>{user.displayName}</h3>
              <p>{user.email}</p>
            </div> */}
            <img src={user.photoURL} alt="" />
          </div>

          <div>

            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={showProfilePictureInCursor}
                    onChange={handleShowProfilePictureInCursorChange}
                  />
                } label="Show profile picture in Cursor" />
              <FormControlLabel
                control={
                  <Switch
                    checked={showProfilePictureInAvatar}
                    onChange={handleShowProfilePictureInAvatarChange}
                  />
                } label="Show profile picture in Calendar Avatar" />
            </FormGroup>
            <br></br>
            <FormGroup>
              <InputLabel id="demo-simple-select-label">Start Page</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={startPage}
                label="Start page"
                onChange={handleStartPage}
              >
                <MenuItem value={10}>Calendar</MenuItem>
                <MenuItem value={20}>Agenda</MenuItem>
                <MenuItem value={30}>Todo</MenuItem>
                <MenuItem value={40}>Settings</MenuItem>
              </Select>
            </FormGroup>
          </div>
        </div>
        : <></>
      }

      {/* ---------------------------- COLOR CONTENT ----------------------------  */}

      {(pageContent === 'colorContent') ?
        <div style={{ paddingLeft: "2rem" }}>
          <div className='settings-header'>
            <h2>Color Settings</h2>
            <h3>{user.displayName}</h3>
          </div>
          <div>
            <div className="color-selector">
              <Colorful
                color={colorInput}
                disableAlpha={true}
                onChange={(color) => {
                  // setColorDiv(color.hex)
                  setColorInput(color.hex);
                }}
              />
              <div
                className={'avatar-settings-container'}
              >
                <p>{user.displayName.charAt(0).toUpperCase()}</p>
                <div
                  style={{
                    backgroundColor: convertHexToRGBA(colorInput, 0.5),
                    backgroundImage: userSettings.showProfilePictureInAvatar ? `url(${userSettings.userImage})` : "",
                    userSelect: 'none',
                    boxShadow: `inset 0px 0px 0px 2px ${borderInput}, 0px 8px 16px rgba(0,0,0,0.4)`,
                    BorderColor: borderInput,
                    backgroundSize: "contain",
                  }}
                  alt="name"
                  height={22}
                  width={22}
                  className={'avatar-settings-background'}
                >
                </div>
                <svg
                  style={{
                    position: 'absolute',
                    bottom: -7,
                    left: -7
                  }}
                  width="13"
                  height="13"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 12H9.17157C10.9534 12 11.8457 9.84572 10.5858 8.58579L3.41421 1.41421C2.15428 0.154281 0 1.04662 0 2.82843V10C0 11.1046 0.89543 12 2 12Z"
                    fill={convertHexToRGBA(borderInput)}
                  />
                </svg>
              </div>
              <Colorful
                color={borderInput}
                disableAlpha={true}
                onChange={(color) => {
                  setBorderInput(color.hex);
                }}
              />
            </div>
            <br></br>
            <br></br>
            <Button variant='outline' onClick={saveColor}>Set color</Button>
            {/* <button onClick={saveSettings}>Save settings</button> */}
          </div>
        </div>
        : <></>
      }




      {/* ---------------------------- PASSWORD CONTENT ----------------------------  */}

      {(pageContent === 'passwordContent') ?
        <div style={{ paddingLeft: "2rem" }}>
          <div className='settings-header'>
            <h2>Password Settings</h2>
          </div>
          <div className='settings-content-header'>
            <div className='settings-content-content'>
              <h3>{user.displayName}</h3>
              <p>{user.email}</p>
            </div>
            <img src={user.photoURL} alt="" />
          </div>
        </div>
        : <></>
      }



    </div>

  );


}

export default Settings