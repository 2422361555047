import React, { useState } from 'react';

import './SignInForm.css'

import { useAuth } from '../Hooks/useAuth';

const SignInForm = (onExitPopup) => {

  const { onLoginWithEmail } = useAuth()
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')

  const handlePassword = (event) => {
    setPassword(event.target.value);

  }
  const handleEmail = (event) => {
    setEmail(event.target.value);
  }

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      onLoginWithEmail(email, password)
    }
  }

  return (
    <div id="login-container" className='login-container'>
      <div id='login-form' className='login-form'>
        <form action="">
          <p>Email</p>
          <input
            placeholder='Enter Email'
            type="email"
            value={email}
            onChange={handleEmail}
            onKeyDown={onKeyDown}
          />
          <p>Password</p>
          <input
            placeholder='Enter Password'
            type="password"
            value={password}
            onChange={handlePassword}
            onKeyDown={onKeyDown}
          />
        </form>
        <button
          onClick={() => { onLoginWithEmail(email, password) }}
        >Log-in</button>
      </div>
    </div>
  )
}

export default SignInForm;