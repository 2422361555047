import { useEffect, useState } from "react";
import { useAuth } from "../Hooks/useAuth";

import SignInForm from "../Components/SignInForm";
import logo from "../img/logo.png";

import "./LogIn.css";

const LogIn = () => {
  const { onLogin } = useAuth();
  const [loginWithEmail, setloginWithEmail] = useState(false);

  useEffect(() => {
    document.addEventListener("mousedown", removePopup);
    document.addEventListener("keydown", removePopup);
  })
  
  const removePopup = (e) => {
    if (e.target === document.getElementById("login-container")) {
      setloginWithEmail(false)
    }
    if (e.key === 'Escape') {
      setloginWithEmail(false)

    }
  }

  return (
    <div>
      <img className="welcome-image" alt="Logo" src={logo}></img>
      <h1 className="buttons">
        <button className="login-with-google-btn" onClick={onLogin}>
          Log in with google
        </button>
        <button
          className="login-with-email-btn"
          onClick={() => setloginWithEmail(true)}
        >
          Log in with email
        </button>
      </h1>
      {loginWithEmail ? <SignInForm onExitPopup={setloginWithEmail} /> : ""}
    </div>
  );
};

export default LogIn;
