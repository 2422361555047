import Todo from '../Components/Todo';
import { auth } from '../Utils/firebaseConfig'
import { useState } from 'react';

import './Todo.css';

const TodoView = () => {
  const [isLoggedin, setIsLoggedIn] = useState(false)

  auth.onAuthStateChanged((user) => {
    if (user) {
      setIsLoggedIn(!!user);
    } else {
      window.location.href = '/'
    }
  });
  return (
    <div>
      {isLoggedin ? (
        <Todo />
      ) : <></>}
    </div>
  );
}

export default TodoView;
