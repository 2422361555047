import React, { useRef, useState, createRef, forwardRef, useEffect } from 'react';

import { uuidv4 } from '@firebase/util';

import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DragHandleIcon from '@mui/icons-material/DragHandle';


import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';



import { database, onSnapshot, collection, query, orderBy, where } from '../Utils/firebaseConfig';
import { addTodoList, addTodo, updateTodo, deleteTodo, deleteList, updateList, reorderTodo } from '../Utils/firebaseCRUD';


import './Todo.css';
import { Icon } from '@mui/material';

const todoDatabase = 'todos'


const TodoForm = ({ addTodoItem, currentTodoList, saveListEdit, setActiveList, editListBool, toggleDoneTodos, numDoneTodos }) => {
  const [content, setContent] = useState(currentTodoList.title);
  const [todoContent, setTodoContent] = useState("")
  const [desc, setDesc] = useState("");


  const editTextfield = (e) => {
    setContent(e.target.value)
  }

  const handleTitleChange = (e) => {
    setTodoContent(e.target.value)
  }
  const handleDescChange = (e) => {
    setDesc(e.target.value)
  }

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      if (event.target.nodeName === "INPUT") {
        saveNewTodo()
      }
      event.target.blur();
    }
  }

  const saveNewTodo = () => {
    addTodoItem(todoContent, desc);
    setTodoContent("")
    setDesc("")
  }

  const onBlur = (event) => {
    if (event.target.classList.contains('todo-content')) {
      saveListEdit(content)
      setActiveList({
        ...currentTodoList,
        title: content,
        desc: desc
      })
      editListBool.current = !editListBool.current
      setContent("")
      event.target.blur();

    } else if (event.target.classList.contains('todo-list-input')) {
      console.log('regular input')
      addTodoItem(todoContent, desc);
      setTodoContent("")
      event.target.blur();
    }
  }

  const blurTextBox = (event) => {
    event.target.blur()
  }



  return (
    <div className='todo-header-main'>
      {
        currentTodoList.title
          ? <div className='todo-form-header'>
            {
              editListBool.current ?
                <input
                  className='todo-content todo-header'
                  type="text"
                  aria-label="list name"
                  value={content}
                  onChange={editTextfield}
                  onKeyDown={onKeyDown}
                  onBlur={onBlur}
                  autoFocus
                />
                : <h2>{currentTodoList.title}</h2>
            }

            <IconButton
              className='btn-edit-list'
              aria-label="edit"
              size='medium'
              onClick={() => {
                setContent(currentTodoList.title) // FIX THAT STATE HANGS FROM LAST EDIT
                editListBool.current = !editListBool.current
              }
              }
            >

              <EditIcon
                fontSize="medium"
              />
            </IconButton>

          </div>
          : <div className='todo-form-header'>
            <h2>{currentTodoList.title}
            </h2>

          </div>
      }


      <div className="add-list">
        <TextField
          variant='standard'
          className='todo-list-input'
          type="text"
          aria-label="Todo name"
          label="Title"
          value={todoContent}
          onChange={handleTitleChange}
          onKeyDown={onKeyDown}
          onBlur={blurTextBox}
          autoFocus
        />
        <TextField
          variant='standard'
          className='todo-list-input'
          type="text"
          aria-label="Todo name"
          label="Description"
          value={desc}
          onChange={handleDescChange}
          onKeyDown={onKeyDown}
          onBlur={blurTextBox}

          autoFocus
        />
        <IconButton
          className='btn-add todo-button'
          aria-label="add todo"
          size='small'
          onClick={() => {
            addTodoItem(todoContent, desc)
            setTodoContent("")
            setDesc("")

          }}
        >
          <AddBoxIcon
            fontSize="small"
          />
        </IconButton>

      </div>

      {/* <Button className='btn-checked' variant='outlined' onClick={() => toggleDoneTodos(false)}>Active - {numDoneTodos.uncomplete}</Button>
      <Button className='btn-checked' variant='outlined' onClick={() => toggleDoneTodos(true)}>Completed - {numDoneTodos.complete}</Button> */}
    </div>
  );
}


const TodoList = ({ lists, addNewList, handleListClick, handleListitemDelete, handleStyleActiveList }) => {
  const [listValue, setListValue] = useState("")

  const editTextfield = (e) => {
    setListValue(e.target.value)
  }

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      addNewList(listValue);
      setListValue("")
    }
  }

  return (
    <div className="todo-list-container">
      <div className="list-header-main">
        <div className="todo-list-header">
          <h2>Lists</h2>
        </div>
        <div className="add-list">
          <TextField
            variant='standard'
            className='todo-list-input'
            type="text"
            aria-label="list name"
            label="Add new list"
            value={listValue}
            onChange={editTextfield}
            onKeyDown={onKeyDown}
            autoFocus
          />
          <IconButton
            className='btn-add todo-button'
            aria-label="add todo"
            size='small'
            onClick={() => {
              setListValue("")
              addNewList(listValue)
            }}
          >
            <AddBoxIcon
              fontSize="small"
            />
          </IconButton>
        </div>
      </div>

      {lists.map((todo, index) => (
        <ListItem
          key={uuidv4()}
          content={todo}
          handleListClick={() => { handleListClick(todo) }}
          handleListitemDelete={() => handleListitemDelete(todo)}
          index={index}
          handleStyleActiveList={handleStyleActiveList}
        />
      ))}
    </div>
  );
}


const ListItem = ({ content, handleListClick, handleListitemDelete, handleStyleActiveList, index }) => {

  return (
    <div
      className='list-item-container'
      ref={handleStyleActiveList}
    >
      <div
        className={'list-item'}
        id={content.id}
        onClick={handleListClick}
      >
        <div className="todo-content">{content.title}</div>
      </div>

      <IconButton
        className='btn-delete todo-button'
        aria-label="delete"
        size='small'
        onClick={handleListitemDelete}
      >
        <DeleteIcon
          fontSize="small"
        />
      </IconButton>
    </div>
  );
}

const TodoItem = ({ title, desc, setData, handleChange, checked, handleTodoItemDelete, id, editTodoBool, index, handleTodoEditChange, handleClick, handleDrag }) => {
  const [content, setContent] = useState(title);
  const [description, setDescription] = useState(desc);

  const handleDescChange = (e) => {
    setDescription(e.target.value)
  }
  const handleContentChange = (e) => {
    setContent(e.target.value)
  }

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      saveTodo()
      // event.target.blur();
    } else if (event.key === "Escape") {
      cancel()
    }
  }

  const cancel = (e) => {
    const currentTarget = e.currentTarget;
    requestAnimationFrame(() => {
      if (!currentTarget.contains(document.activeElement)) {
        handleTodoEditChange(index)
      }
    });
  }

  const saveTodo = () => {
    handleTodoEditChange(index)
    setData(content, description)
  }


  return (
    <div
      id={id}
      className={`todo-item-container ${checked ? "todo-done" : ""}`}
      onBlur={cancel}
    >
      <div className='todo-item-drag-icon' {...handleDrag}>
        <IconButton>
          <DragHandleIcon />
        </IconButton>
      </div>
      <div className="todo-item">
        {
          editTodoBool
            ?
            <div>
              <TextField
                variant='standard'
                className='todo-content'
                type="text"
                aria-label="list name"
                label="Title"
                value={content}
                onChange={handleContentChange}
                onKeyDown={onKeyDown}
                autoFocus
              />
              <TextField
                variant='standard'
                label="Description"
                className='todo-content'
                type="text"
                aria-label="list name"
                value={description}
                onChange={handleDescChange}
                onKeyDown={onKeyDown}
              />
            </div>
            : < div
              className='todo-content'
              onClick={handleClick}
            > {title} <div> {desc}</div></div >
        }
        <Checkbox
          inputProps={{ 'aria-label': 'controlled' }}
          onClick={handleChange}
          checked={checked}
        />
      </div >
      <IconButton
        className='btn-edit'
        aria-label="edit"
        size='small'
        onClick={() => {
          handleTodoEditChange(index)
          setContent(title) // FIX THAT STATE HANGS FROM LAST EDIT
          setDescription(desc) // FIX THAT STATE HANGS FROM LAST EDIT
        }}
      >
        <EditIcon
          fontSize="small"
        />
      </IconButton>

      <IconButton
        className='btn-delete'
        aria-label="delete"
        size='small'
        onClick={handleTodoItemDelete}
      >
        <DeleteIcon
          fontSize="small"
        />
      </IconButton>
    </div>
  );
}


const Todo = () => {

  const [allTodos, setAllTodos] = useState([])
  const [allLists, setAllLists] = useState([])
  const [activeList, setActiveList] = useState("null");
  const editListBool = useRef(false)
  const [editTodoBool, setEditTodoBool] = useState([])
  // const [showDoneTodos, toggleDoneTodos] = useState(false)
  const [numDoneTodos, setNumDoneTodos] = useState([])

  const styleActiveList = useRef([])
  styleActiveList.current = []








  // SET LISTENER TO FIREBASE TODO LIST
  useEffect(() => {
    const userRef = collection(database, todoDatabase);
    const sort = query(userRef, orderBy('title', 'asc'))
    const unsubsctibeTodoList = onSnapshot(sort, (snapshot) => {
      setAllLists(snapshot.docs.map(doc => ({
        id: doc.data().id,
        title: doc.data().title,
        key: doc.id
      })))

      if (activeList === "null") {
        setActiveList({
          ...snapshot.docs[0].data(),
          key: snapshot.docs[0].id
        })
      }
      snapshot.docs.map(doc => {
        if (doc.data().id === activeList.id) {
          setActiveList({
            ...doc.data(),
            key: doc.id
          })
        }
      })
    })

    return () => {
      console.log('unsubscribed')
      unsubsctibeTodoList()
    }
  }, [activeList.id])



  // SET LISTENERS TO ACTIVE LIST TODOS
  useEffect(() => {
    if (activeList === 'null') {
    } else {
      const userRef = collection(database, todoDatabase, activeList.key, "todos");
      const sort = query(userRef, orderBy('order', 'asc'))
      const unsubsctibeAllTodos = onSnapshot(sort, (snapshot) => {
        setAllTodos(snapshot.docs.map(doc => ({
          id: doc.data().id,
          title: doc.data().title,
          desc: doc.data().desc,
          checked: doc.data().checked,
          order: doc.data().order
        })
        ))
        let done = []
        let notDone = []
        snapshot.docs.forEach((todo) => {
          if (todo.data().checked === true) {
            done.push(todo)
          } else if (todo.data().checked === false)
            notDone.push(todo.data())
        })
        setNumDoneTodos({
          complete: done.length,
          uncomplete: notDone.length
        })
      })

      return () => {
        unsubsctibeAllTodos()
      }
    }
  }, [activeList])


  // SET STYLING TO DONE ELEMENTS
  useEffect(() => {
    // allTodos.map((todo, index) => {
    //   if (todo.checked === true) {
    //     let element = document.getElementById(todo.id)
    //     element.classList.add('todo-done')
    //   } else if (todo.id === null) {

    //   } else {
    //     let element = document.getElementById(todo.id)
    //     if (element !== null) {
    //       element.classList.remove('todo-done')
    //     }
    //   }
    // })
    setEditTodoBool(allTodos.map(() => { return false }))
  }, [allTodos])



  // SET STYLING TO SELECTED ELEMENT
  const handleStyleActiveList = (el) => {
    if (el && !styleActiveList.current.includes(el)) {
      if (el.firstChild.id === activeList.id) {
        // console.log('lollll')
        el.firstChild.classList.add('active-list')
      }
      styleActiveList.current.push(el)
    }
  }


  const handleDoneTodo = (el) => {
    if (el && !styleActiveList.current.includes(el)) {
      if (el.firstChild.id === activeList.id) {
        // console.log('lollll')
        el.firstChild.classList.add('active-list')
      }
      styleActiveList.current.push(el)
    }
  }


  // SET TODO EDITING PROPERTY TO OPOSITE
  const handleChange = (event) => {
    updateTodo(todoDatabase, activeList.key, event.id, event.title, event.desc, !event.checked, event.order+100)
  }

  // SAVE THE EDITED TODO
  const saveEdit = (event, title, description) => {
    updateTodo(todoDatabase, activeList.key, event.id, title, description, event.checked, event.order)
  }

  // ADD TODO ITEM
  const addTodoItem = (todo, desc) => {
    if (!todo.trim()) return;
    addTodo(todoDatabase, activeList.key, uuidv4(), todo, desc, false, false, allTodos.length + 1)
  }

  // ADD NEW TODO LIST
  const addNewList = (newList) => {
    if (!newList.trim()) return;
    addTodoList(todoDatabase, uuidv4(), newList)
  }

  // HANDLE RENDER OF NEW TODO LIST
  const handleListClick = (todo) => {
    setActiveList(todo)
    // toggleDoneTodos(false)
  }


  // DELETE TODO LIST AND ALL CHILDREN -> Children deleted inside deleteList Fn.
  const handleListitemDelete = (list) => {
    deleteList(todoDatabase, list.key, list.id)
  }

  // DELETE ONE TODO IN LIST
  const handleTodoItemDelete = (todo) => {
    deleteTodo(todoDatabase, activeList.key, todo.id)
  }

  // SAVE THE LIST AFTER EDITING WITH NEW TITLE
  const saveListEdit = (newTitle) => {
    updateList(todoDatabase, activeList.id, newTitle)
  }

  // HANDLE EDIT MODE FOR TODOS
  function handleTodoEditChange(index) {
    const updatedTodoState = editTodoBool.map((c, i) => {
      if (i === index) {
        // Edit the editing boolean
        return !c;
      } else {
        // The rest haven't changed
        return c;
      }
    });
    setEditTodoBool(updatedTodoState);
  }


  // Function to update list on drop
  const handleDrop = (droppedItem) => {
    if (!droppedItem.destination) return;
    let thisTodo = allTodos.find(x => x.id === droppedItem.draggableId);
    let thisTodoIndex = allTodos.findIndex(x => x.id === droppedItem.draggableId);
    let todoDestinationIndex = droppedItem.destination.index;
    let order;

    if (todoDestinationIndex === 0) {
      order = allTodos[todoDestinationIndex].order / 2;

    } else if (todoDestinationIndex === allTodos.length - 1) {
      // start of array
      // console.log('Start')
      order = 1 + (allTodos[droppedItem.destination.index].order + allTodos[droppedItem.destination.index].order) / 2;

    } else {
      if (thisTodoIndex > droppedItem.destination.index) {
        // end of array
        // console.log('End')
        order = (allTodos[droppedItem.destination.index].order + allTodos[droppedItem.destination.index - 1].order) / 2;

      } else if (thisTodoIndex < droppedItem.destination.index) {
        // Middle of array
        // console.log('Middle')
        order = (allTodos[droppedItem.destination.index].order + allTodos[droppedItem.destination.index + 1].order) / 2;
      }
    }
    reorderTodo(todoDatabase, activeList.key, thisTodo.id, order);
  };

// console.log(allTodos.map((todo) => todo.order))

  
  return (
    <div className="main-container">
      <div className='current-todo-container'>
        <TodoForm
          addTodoItem={addTodoItem}
          currentTodoList={activeList}
          saveListEdit={saveListEdit}
          setActiveList={setActiveList}
          editListBool={editListBool}
          // toggleDoneTodos={toggleDoneTodos}
          numDoneTodos={numDoneTodos}
        />

        <DragDropContext onDragEnd={handleDrop}>
          <Droppable droppableId="list-container">
            {(provided) => (
              <div
                className="list-container"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {
                  allTodos.map((todo, index) => ( //.filter(item => item.checked === showDoneTodos).
                    <Draggable key={todo.id} draggableId={`${todo.id}`} index={index}>

                      {(provided) => (
                        <div
                          className="item-container"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          {<TodoItem
                            id={todo.id}
                            handleChange={() => { handleChange(todo) }}
                            key={uuidv4()}
                            title={todo.title}
                            desc={todo.desc}
                            checked={todo.checked}
                            setData={(content, description) => { saveEdit(todo, content, description) }}
                            handleTodoItemDelete={() => { handleTodoItemDelete(todo) }}
                            editTodoBool={editTodoBool[index]}
                            index={index}
                            handleTodoEditChange={handleTodoEditChange}
                            handleDrag={{ ...provided.dragHandleProps }}
                          />}
                        </div>
                      )}
                    </Draggable>
                  ))
                }
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

      </div>
      <TodoList
        lists={allLists}
        addNewList={addNewList}
        handleListClick={handleListClick}
        handleListitemDelete={handleListitemDelete}
        handleStyleActiveList={handleStyleActiveList}
      />
    </div>
  );
}

export default Todo;