// LiveblocksProviderWrapper.js
import { useAuth } from '../Hooks/useAuth';
import { LiveblocksProvider } from '@liveblocks/react';
import { httpsCallable, functions } from "../Utils/firebaseConfig";


const LiveblocksProviderWrapper = ({ children }) => {
  const { user, loading } = useAuth();


  // Auth endpoint function
  const authEndpoint = async (room) => {
    const authFunction = httpsCallable(functions, "liveblocksAuth");
    const response = await authFunction({ room });

    console.log(response)

    // Ensure this returns { token: "..." }
    if (!response.data || !response.data.token) {
      throw new Error("Auth endpoint did not return a valid token.");
    }
    return response.data; // This should return the token as { token: "SECRET_STRING" }
  };

  if (loading) return <p>Loading...</p>;
  if (!user) return <p>Please log in to access this feature.</p>;

  return (
    <LiveblocksProvider
      authEndpoint={async (room) => {
        // Fetch your authentication endpoint and retrieve your access or ID token
        const authData = await authEndpoint(room);
        return authData
      }}
    >      {children}
    </LiveblocksProvider>
  );
};

export default LiveblocksProviderWrapper;