import {
  useCallback,
  useEffect,
  useRef,
  useLayoutEffect,
  useState,
} from "react";
import { PerfectCursor } from "perfect-cursors";


// Spline animated cursor
export default function Cursor({ id, x, y, name, color, profilePicture }) {
  const rCursor = useRef(null);
  // console.log(x, y, name)
  // console.log('Cursor color:', color)

  // REMOVED THE PERFECT CURSOR EFFECT HERE.. ONLY USING TRANSITION IN THE RENDER FUCTION
  const animateCursor = useCallback((point) => {
    const elm = rCursor.current;
    if (!elm) return;
    // elm.style.setProperty(
    //   // "transform",
    //   // `translate(${point[0]}px, ${point[1]}px)`
    // );
  }, []);

  const onPointMove = usePerfectCursor(animateCursor);

  useEffect(() => onPointMove([x, y]), [onPointMove, x, y]);


  const CURSOR_SIZE = 35;

  const COLORS = {
    yellow: { default: "#FFBB00", dim: '#503F10' },
    red: { default: '#FF3366', dim: '#501D2A' },
    blue: { default: "#0088FF", dim: "#103250" },
    green: { default: "#22DD88", dim: "#194832" },
    orange: { default: "#FF8800", dim: "#503210" },
    pink: { default: "#FF0099", dim: "#501037" },
    purple: { default: "#AA44FF", dim: "#3B2150" },
    orange1: { default: "#FF8800", dim: "#503210" },
    blue1: { default: "#0088FF", dim: "#103250" },
    green1: { default: "#22DD88", dim: "#194832" },
  };


  const convertHexToRGBA = (hexCode, opacity = 1) => {
    let hex = hexCode.replace('#', '');

    if (hex.length === 3) {
      hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
    }
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    /* Backward compatibility for whole number based opacity values. */
    if (opacity > 1 && opacity <= 100) {
      opacity = opacity / 100;
    }

    return `rgba(${r},${g},${b},${opacity})`;
  };


  const getColor = id => {
    const firstDigitNum = id % 10;
    return Object.values(COLORS)[firstDigitNum];
  };


  return (
    <div
      ref={rCursor}
      className='cursor-image'
      style={{
        position: "absolute",
        left: 0,
        top: -76,
        transition: "transform 120ms linear",                 // THEESE ARE THE ONES CONTROLLING TRANSITIONING OF CURSORS
        transform: `translateX(${x}px) translateY(${y}px)`,
      }}
    >
      {color.dim ? // TODO: THIS MIGHT FIX THE RANDOM UNDEFINED PROBLEM
      <div
        style={{
          width: CURSOR_SIZE,
          height: CURSOR_SIZE,
          position: 'absolute',
          fontSize: 17,
          userSelect: 'none',
          backgroundColor: (color.dim !== undefined ) ? convertHexToRGBA(color.dim, 0.5) : "rgb(255,255,255)",
          boxShadow: `inset 0px 0px 0px 2px ${color.color}, 0px 8px 16px rgba(0,0,0,0.4)`,
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundImage: profilePicture ? `url(${profilePicture})` : "",
          backgroundSize: "contain",

        }}
      >
        <p>{name.charAt(0).toUpperCase()}</p>
        <svg
          style={{
            position: 'absolute',
            bottom: -2,
            left: -2
          }}
          width="10"
          height="10"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 12H9.17157C10.9534 12 11.8457 9.84572 10.5858 8.58579L3.41421 1.41421C2.15428 0.154281 0 1.04662 0 2.82843V10C0 11.1046 0.89543 12 2 12Z"
            fill={(color.dim !== undefined ) ? convertHexToRGBA(color.color, 0.5) : "rgb(255,255,255)"}
          />
        </svg>
      </div>
      : <></>

      }
    </div>
  );
}

// Hook for adding perfect-cursors
function usePerfectCursor(cb, point) {
  const useIsomorphicLayoutEffect =
    typeof window !== "undefined" ? useLayoutEffect : useEffect;

  const [pc] = useState(() => new PerfectCursor(cb));

  useIsomorphicLayoutEffect(() => {
    if (point) {
      pc.addPoint(point);
    }
    return () => pc.dispose();
  }, [pc]);

  return useCallback((point) => pc.addPoint(point), [pc]);
}

// SVG cursor shape
// function CursorSvg({ color }) {
//   return (
//     <svg
//       width="322" height="424" viewBox="0 0 24 36" fill="none">
//       <path
//         fill={color}
//         d="M5.65376 12.3673H5.46026L5.31717 12.4976L0.500002 16.8829L0.500002 1.19841L11.7841 12.3673H5.65376Z"
//       />
//     </svg>
//   );
// }