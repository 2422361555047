export const calculateOffScreenPosition = (x, y, containerWidth, containerHeight, resourceColumnWidth) => {
  const PADDING = 10;
  const THRESHOLD = 100; // Distance in pixels before showing the indicator
  
  // Get the calendar elements
  const calendar = document.querySelector('.mbsc-timeline.mbsc-flex-1-1');
  const header = document.querySelector('.mbsc-timeline-header-sticky');
  const resources = document.querySelector('.mbsc-timeline-resources');
  
  if (!calendar || !header || !resources) {
    return { x, y, isOffScreen: false };
  }

  const calendarRect = calendar.getBoundingClientRect();
  const headerRect = header.getBoundingClientRect();
  const resourcesRect = resources.getBoundingClientRect();

  // Calculate boundaries
  const bounds = {
    left: resourcesRect.width + PADDING,
    right: calendarRect.right - PADDING,
    top: headerRect.height + PADDING,
    bottom: calendarRect.bottom - PADDING
  };

  let isOffScreen = false;
  let newX = x;  // Start with current cursor position
  let newY = y;  // Start with current cursor position
  let angle = 0;

  // Calculate distance from boundaries
  const distanceLeft = x - bounds.left;
  const distanceRight = bounds.right - x;
  const distanceTop = y - bounds.top;
  const distanceBottom = bounds.bottom - y;

  // Check if cursor is beyond threshold
  if (distanceLeft < -THRESHOLD) {
    newX = bounds.left;
    newY = Math.min(Math.max(y, bounds.top), bounds.bottom); // Clamp Y position between top and bottom
    isOffScreen = true;
    angle = 270;
  } else if (distanceRight < -THRESHOLD) {
    newX = bounds.right;
    newY = Math.min(Math.max(y, bounds.top), bounds.bottom); // Clamp Y position between top and bottom
    isOffScreen = true;
    angle = 90;
  }

  if (distanceTop < -THRESHOLD) {
    newY = bounds.top;
    newX = Math.min(Math.max(x, bounds.left), bounds.right); // Clamp X position between left and right
    isOffScreen = true;
    angle = 0;
  } else if (distanceBottom < -THRESHOLD) {
    newY = bounds.bottom;
    newX = Math.min(Math.max(x, bounds.left), bounds.right); // Clamp X position between left and right
    isOffScreen = true;
    angle = 180;
  }

  return {
    x: newX,
    y: newY,
    isOffScreen,
    angle
  };
}; 