import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
  useMemo,
} from "react";
import { gapi } from "gapi-script";
import { Select, MenuItem, Button, InputLabel } from "@mui/material";
import {
  setDoc,
  doc,
  database,
  collection,
  query,
  getDocs,
  where,
  getDoc,
  onSnapshot,
  Timestamp,
} from "./firebaseConfig";
import { deleteField } from "firebase/firestore";
import { AuthContext } from "../Hooks/AuthProvider";
import { httpsCallable, functions } from "./firebaseConfig";
import { GoogleLogin } from "@react-oauth/google";
import { useGoogleLogin } from "@react-oauth/google";

import { Datepicker, localeEn } from "@mobiscroll/react";
// import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import styles from "./CalendarIntegration.module.css";

const buttonStyle = {
  display: "flex",
  alignItems: "center",
  background: "white",
  color: "#444",
  width: "auto",
  borderRadius: "5px",
  border: "thin solid #888",
  boxShadow: "1px 1px 1px grey",
  cursor: "pointer",
  padding: "7px 12px", // Adjust padding for a better look
  justifyContent: "flex-start",
  gap: "10px",
};

const iconStyle = {
  background:
    'url("https://upload.wikimedia.org/wikipedia/commons/a/a5/Google_Calendar_icon_%282020%29.svg") transparent 5px 50% no-repeat',
  display: "inline-block",
  verticalAlign: "middle",
  width: "40px",
  height: "35px",
  backgroundPositionX: "0px",
};

const buttonTextStyle = {
  display: "inline-block",
  verticalAlign: "middle",

  fontSize: "14px",
  // fontWeight: 'bold',
  fontFamily: "Roboto, sans-serif",
};

// const handleGoogleCalendarSignIn = () => {
//   console.log("Google Calendar sign-in initiated.");
//   // Implement your Google Calendar sign-in logic here
// };

const CalendarIntegration = () => {
  const {
    user,
    gCalAccessToken,
    setGcalAccessToken,
    setGcalRefreshToken,
    userCalendars,
    setUserCalendars,
    setUserSelectedCalendar,
    userSelectedCalendar,
    userSettings,
    revokeAccess,
  } = useContext(AuthContext);

  // const [calendars, setCalendars] = useState([]);
  const [gCalEvents, setGcalEvents] = useState([]);
  const [syncStartDate, setSyncStartDate] = useState(new Date());
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [isRevoking, setIsRevoking] = useState(false);
  const [isSyncing, setIsSyncing] = useState(
    userSettings?.hasActiveSync || false
  );
  const [syncInProgress, setSyncInProgress] = useState(
    userSettings?.isSyncing || false
  );

  const [dots, setDots] = useState(".");

  const [isStoppingSync, setIsStoppingSync] = useState(false);

  const [statusMessage, setStatusMessage] = useState("");
  const [statusError, setStatusError] = useState(null);

  const [syncProgress, setSyncProgress] = useState(null);

  const [deletionProgress, setDeletionProgress] = useState(null);
  console.log(syncStartDate);
  const listAllCalendars = async (token) => {
    if (userCalendars?.length > 0) {
      // setCalendars(userCalendars);
      return;
    }

    const url = "https://www.googleapis.com/calendar/v3/users/me/calendarList";
    try {
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(`Error fetching calendars: ${response.statusText}`);
      }

      if (data.items?.length > 0) {
        setUserCalendars(data.items);

        // Cache the calendar list
        await setDoc(
          doc(database, "user-settings", user.email),
          {
            userCalendars: data.items,
          },
          { merge: true }
        );
      }
    } catch (error) {
      console.error("Error in listAllCalendars:", error);
    }
  };

  const cleanupOperationStates = () => {
    setTimeout(() => {
      if (user?.email) {
        setDoc(
          doc(database, "user-settings", user.email),
          {
            syncProgress: deleteField(),
            lastOperation: deleteField(),
          },
          { merge: true }
        ).catch((error) => {
          console.error("Error cleaning up progress states:", error);
        });
      }
    }, 60000); // 1 minute
  };

  const syncCalendar = async (startDate) => {
    setStatusMessage("Starting sync...");
    setStatusError(null);
    setIsSyncing(true);
    setSyncProgress(null);
    setSyncInProgress(true);

    try {
      console.log("Sync date:", startDate.toString());

      const firebaseTimestamp = Timestamp.fromDate(startDate);

      await setDoc(
        doc(database, "user-settings", user.email),
        {
          syncStartDate: firebaseTimestamp,
          timezone: "Europe/Oslo",
        },
        { merge: true }
      );

      const syncFunction = httpsCallable(
        functions,
        "syncExistingEventsToGoogle",
        {
          timeout: 540000, // 9 minutes (540,000ms)
        }
      );
      const result = await syncFunction({
        startDate: startDate.toISOString(),
        timezone: "Europe/Oslo",
      });

      if (result.data.success) {
        cleanupOperationStates();
      } else {
        setSyncInProgress(false);
        setStatusError(
          result.data.message || "Sync failed without error details"
        );
      }
    } catch (error) {
      console.error("Sync error:", error);

      if (error.code === "deadline-exceeded") {
        // Don't treat as error, sync is still running
        setStatusMessage(
          "Sync continues in background. Progress updates will appear here."
        );
      } else {
        setStatusError(error.message || "Unknown error occurred");
        setStatusMessage("Sync failed");
        setSyncInProgress(false);

        // Reset sync state for real errors
        await setDoc(
          doc(database, "user-settings", user.email),
          {
            isSyncing: false,
            hasActiveSync: false,
          },
          { merge: true }
        );
      }
    }
  };

  console.log(userSettings);

  const stopSync = async () => {
    setStatusMessage("Stopping sync and removing events...");
    setStatusError(null);
    setIsStoppingSync(true);

    try {
      const deleteFunction = httpsCallable(
        functions,
        "deleteGoogleCalendarEvents",
        {
          timeout: 540000, // 9 minutes (540,000ms)
        }
      );
      await deleteFunction();

      await setDoc(
        doc(database, "user-settings", user.email),
        {
          isSyncing: false,
          hasActiveSync: false,
          lastSyncDate: null,
          syncProgress: null,
        },
        { merge: true }
      );

      setSyncInProgress(false);
      setIsSyncing(false);
      setStatusMessage("Sync stopped and events removed successfully");
      setSyncProgress(null);
      cleanupOperationStates();
    } catch (error) {
      console.error("Sync error:", error);

      if (error.code === "deadline-exceeded") {
        // Don't treat as error, sync is still running
        setStatusMessage(
          "Sync continues in background. Progress updates will appear here."
        );
      } else {
        console.error("Error stopping sync:", error);
        const errorMessage =
          error.code === "functions/internal"
            ? "Failed to stop sync. Please try again."
            : error.message || "Unknown error occurred";
        setStatusError(errorMessage);
        setStatusMessage("Failed to stop sync");
      }
    } finally {
      setIsStoppingSync(false);
    }
  };

  const handleCalendarSelection = useCallback(
    async (e) => {
      const selectedCalendarId = e.target.value;
      const selectedCalendarObj = userCalendars.find(
        (cal) => cal.id === selectedCalendarId
      );

      if (selectedCalendarObj) {
        setUserSelectedCalendar(selectedCalendarObj.id);

        // Persist both ID and summary to Firebase
        try {
          await setDoc(
            doc(database, "user-settings", user.email),
            {
              userSelectedCalendar: selectedCalendarObj.id,
              userSelectedCalendarSummary: selectedCalendarObj.summary,
            },
            { merge: true }
          );
        } catch (error) {
          console.error("Error saving calendar selection:", error);
        }
      }
    },
    [userCalendars, user?.email]
  );

  const handleSyncCalendar = useCallback((startDate) => {
    console.log("Starting sync with date:", startDate.toString());
    syncCalendar(startDate);
  }, []);

  const handleStopSync = useCallback(() => {
    stopSync();
  }, []);

  const login = useGoogleLogin({
    flow: "auth-code",
    redirect_uri: "https://motion-air-calendar.vercel.app",
    scope:
      "https://www.googleapis.com/auth/user.birthday.read https://www.googleapis.com/auth/user.organization.read https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/calendar",
    onSuccess: (codeResponse) => handleAuth(codeResponse),
  });

  // Modify handleAuth in CalendarIntegration.js to store the refresh token
  const handleAuth = async (googleReturnData) => {
    setIsAuthenticating(true);
    console.log("Starting authentication with code:", googleReturnData.code);
    const authFunction = httpsCallable(functions, "authenticateGoogleCalendar");

    try {
      console.log("Calling auth function...");
      const response = await authFunction({ code: googleReturnData.code });
      console.log("Auth function response:", response);

      await setDoc(
        doc(database, "user-settings", user.email),
        {
          googleCalendarConnected: true,
          lastAuthenticated: new Date().toISOString(),
        },
        { merge: true }
      );
      await listAllCalendars(response.data.access_token);
    } catch (error) {
      console.error("Authentication failed:", error);
      console.error("Error details:", {
        code: error.code,
        message: error.message,
        details: error.details,
        stack: error.stack,
      });
    } finally {
      setIsAuthenticating(false);
    }
  };

  const handleRevoke = async () => {
    setIsRevoking(true);
    try {
      await revokeAccess();
    } finally {
      setIsRevoking(false);
    }
  };

  useEffect(() => {
    const checkAuthStatus = async () => {
      if (gCalAccessToken) {
        try {
          await listAllCalendars(gCalAccessToken);
        } catch (error) {
          console.error("Error checking auth status:", error);
          // If there's an error, the token might be invalid
          setGcalAccessToken(null);
          setGcalRefreshToken(null);
        }
      }
    };

    checkAuthStatus();
  }, [gCalAccessToken]);

  // Update the useEffect for initial load
  useEffect(() => {
    const initializeCalendars = async () => {
      if (!user?.email || !gCalAccessToken) return;

      try {
        const userDoc = await getDoc(
          doc(database, "user-settings", user.email)
        );
        if (userDoc.exists()) {
          const userData = userDoc.data();

          // Set calendars from stored data first
          if (userData.userCalendars?.length > 0) {
            // setCalendars(userData.userCalendars);
            setUserCalendars(userData.userCalendars);
          }

          // Set selected calendar
          if (userData.userSelectedCalendar) {
            setUserSelectedCalendar(userData.userSelectedCalendar);
          }

          // If connected but no calendars, fetch from API
          if (
            userData.googleCalendarConnected &&
            (!userData.userCalendars || userData.userCalendars.length === 0)
          ) {
            await listAllCalendars(gCalAccessToken);
          }
        }
      } catch (error) {
        console.error("Error initializing calendars:", error);
      }
    };

    initializeCalendars();
  }, [user?.email, gCalAccessToken]);

  // useEffect(() => {
  //   // listUpcomingEvents(selectedCalendar);
  //   setUserSelectedCalendar(selectedCalendar);
  // }, [userSelectedCalendar]);

  const listUpcomingEvents = async (calendarId) => {
    // URL for listing events from a specific calendar
    const url = `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${gCalAccessToken}`, // Include the access token
          Accept: "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`Error fetching events: ${response.statusText}`);
      }

      const data = await response.json();
      const events = data.items;

      if (!events || events.length === 0) {
        console.log("No events found.");
        return;
      }

      setGcalEvents(events); // Update state or perform actions with the events
    } catch (error) {
      console.error(`Error: ${error.message}`);
    }
  };

  // Dots animation
  useEffect(() => {
    let interval;
    if (isAuthenticating || isRevoking || isSyncing || isStoppingSync) {
      interval = setInterval(() => {
        setDots((prev) => (prev.length >= 6 ? "." : prev + "."));
      }, 300);
    } else {
      setDots(".");
    }
    return () => clearInterval(interval);
  }, [isAuthenticating, isRevoking, isSyncing, isStoppingSync]);

  // Separate useEffect for progress updates
  useEffect(() => {
    if (!user?.email) return;

    const unsubscribe = onSnapshot(
      doc(database, "user-settings", user.email),
      (doc) => {
        if (doc.exists()) {
          const data = doc.data();

          // Update sync progress
          if (data.syncProgress) {
            setSyncProgress(data.syncProgress);
            if (data.syncProgress.status === "completed") {
              setSyncInProgress(false);
              setIsSyncing(true); // Keep isSyncing true after completion
              setStatusMessage("Sync completed successfully");
              setTimeout(() => setSyncProgress(null), 2000);
              if (data.hasActiveSync) {
                setIsSyncing(true);
              }
            } else if (data.syncProgress.status === "in_progress") {
              setSyncInProgress(true);
              setStatusMessage(
                `Syncing from: ${data.userSelectedCalendarSummary}`
              );
            } else if (data.syncProgress.status === "error") {
              setSyncInProgress(false);
              setIsSyncing(false);
            }
          } else {
            // If syncProgress is null/undefined, rely on hasActiveSync
            setSyncProgress(null);
            setSyncInProgress(false);
            if (data.hasActiveSync) {
              setIsSyncing(true);
            }
          }

          // Handle deletion progress
          if (data.deletionProgress) {
            setDeletionProgress(data.deletionProgress);
            if (data.deletionProgress.status === "in_progress") {
              setIsStoppingSync(true);
              setStatusMessage(
                `Removing events: ${data.deletionProgress.eventsDeleted} / ${data.deletionProgress.totalEvents}`
              );
            } else if (data.deletionProgress.status === "completed") {
              setIsStoppingSync(false);
              setIsSyncing(false);
              setSyncInProgress(false);
              setStatusMessage("Sync stopped and events removed successfully");
              // Clear deletion progress after a delay
              setTimeout(() => {
                setDeletionProgress(null);
              }, 2000);
            }
          }

          // Always update isSyncing based on hasActiveSync
          if (data.hasActiveSync !== undefined) {
            setIsSyncing(data.hasActiveSync);
          }
        }
      }
    );

    return () => unsubscribe();
  }, [user?.email]);

  useEffect(() => {
    if (syncInProgress && syncProgress?.status === "in_progress") {
      setStatusMessage(
        `Syncing from: ${userSettings.userSelectedCalendarSummary}`
      );
    } else if (syncProgress?.status === "completed") {
      setStatusMessage("Sync completed successfully");
    }
  }, [syncInProgress, syncProgress, userSettings.userSelectedCalendarSummary]);

  // Track specific state changes
  // useEffect(() => {
  //   console.warn("📅 Selected calendar changed:", userSelectedCalendar);
  // }, [userSelectedCalendar]);

  // useEffect(() => {
  //   console.warn("⚙️ User settings changed:", userSettings);
  // }, [userSettings]);

  // useEffect(() => {
  //   console.warn("🔄 Sync status changed:", { isSyncing, syncInProgress });
  // }, [isSyncing, syncInProgress]);

  const CalendarDropdown = useMemo(
    () => (
      <Select
        value={userSelectedCalendar}
        onChange={handleCalendarSelection}
        displayEmpty
        fullWidth
        variant="outlined"
        style={{ marginTop: "20px" }}
        disabled={
          !userSettings.googleCalendarConnected ||
          syncInProgress ||
          userSettings?.hasActiveSync
        }
      >
        {userSelectedCalendar === "" && (
          <MenuItem value="">
            <em>Select a Calendar</em>
          </MenuItem>
        )}
        {userCalendars.map((calendar) => (
          <MenuItem key={calendar.id} value={calendar.id}>
            {calendar.summary}
          </MenuItem>
        ))}
      </Select>
    ),
    [
      userSelectedCalendar,
      userCalendars,
      userSettings.googleCalendarConnected,
      syncInProgress,
      userSettings?.hasActiveSync,
      handleCalendarSelection,
    ]
  );

  const SyncButtons = useMemo(
    () => (
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "row",
          gap: "10px",
        }}
      >
        <Button
          sx={{ marginTop: "0px", width: "160px" }}
          variant="contained"
          onClick={() => handleSyncCalendar(syncStartDate)}
          disabled={
            !userSelectedCalendar ||
            syncInProgress ||
            userSettings?.hasActiveSync ||
            isStoppingSync
          }
        >
          {syncInProgress
            ? "Syncing..."
            : userSettings?.hasActiveSync
            ? "Sync Active"
            : "Start Sync"}
        </Button>
        <Button
          sx={{ marginTop: "0px", width: "160px" }}
          variant="contained"
          onClick={handleStopSync}
          disabled={!userSettings?.hasActiveSync || isStoppingSync}
        >
          {isStoppingSync ? "Stopping..." : "Stop Sync"}
        </Button>
      </div>
    ),
    [
      userSelectedCalendar,
      syncInProgress,
      isStoppingSync,
      userSettings?.hasActiveSync,
      handleSyncCalendar,
      handleStopSync,
      syncStartDate,
    ]
  );

  console.log(!isSyncing);
  console.log(userSettings);

  return (
    <div>
      {userSettings.googleCalendarConnected ? (
        <div onClick={handleRevoke} id="gSignInWrapper">
          <div id="customBtn" style={buttonStyle}>
            <span style={iconStyle}></span>
            <span style={buttonTextStyle}>
              {isRevoking
                ? `Revoking access${dots}`
                : "Revoke Google Calendar Access"}
            </span>
          </div>
        </div>
      ) : (
        <div onClick={() => !isAuthenticating && login()} id="gSignInWrapper">
          <div id="customBtn" style={buttonStyle}>
            <span style={iconStyle}></span>
            <span style={buttonTextStyle}>
              {isAuthenticating
                ? `Connecting${dots}`
                : "Connect to Google Calendar"}
            </span>
          </div>
        </div>
      )}
      <>
        <h4
          style={{
            marginTop: "60px",
            marginBottom: "0px",
            color: !userSettings.googleCalendarConnected ? "#BDBDBD" : "",
          }}
        >
          Select a Calendar to sync:{" "}
          {userCalendars.length
            ? `(${userCalendars.length} available)`
            : "(None found)"}
        </h4>
        {CalendarDropdown}
        <div className={styles["date-picker"]}>
          <Datepicker
            value={syncStartDate}
            onChange={(event) => {
              const selectedDate = new Date(event.value);
              selectedDate.setHours(0, 0, 0, 0); // Set to midnight
              console.log("Datepicker selected:", selectedDate.toString());
              setSyncStartDate(selectedDate);
            }}
            locale={localeEn}
            disabled={
              !userSelectedCalendar ||
              syncInProgress ||
              userSettings?.hasActiveSync
            }
            cssClass={styles["date-picker"]}
            showInput={true}
          />
        </div>
        {SyncButtons}
        {userSettings?.hasActiveSync && (
          <div style={{ marginTop: "28px", fontSize: "0.9em" }}>
            If you stop sync, all app-created events in google calendar will be
            deleted
          </div>
        )}
        {(statusMessage ||
          statusError ||
          (syncInProgress && syncProgress?.status === "in_progress") ||
          (isStoppingSync && deletionProgress?.status === "in_progress")) && (
          <div
            style={{
              marginTop: "20px",
              padding: "10px",
              borderRadius: "4px",
              backgroundColor: statusError ? "#FFF3F0" : "#F0F7FF",
              color: statusError ? "#CF1322" : "#096DD9",
              textAlign: "left",
            }}
          >
            {/* Status Message */}
            {isStoppingSync
              ? `Stopping sync${dots}`
              : syncInProgress && syncProgress?.status === "in_progress"
              ? `Syncing from: ${userSettings.userSelectedCalendarSummary}`
              : statusMessage}

            {/* Progress Counter - Only show for sync, not for stop */}
            {!isStoppingSync &&
              syncProgress &&
              syncProgress.status === "in_progress" && (
                <div style={{ marginTop: "8px" }}>
                  Syncing: {syncProgress.processedEvents} /{" "}
                  {syncProgress.totalEvents} events (
                  {Math.round(
                    (syncProgress.processedEvents / syncProgress.totalEvents) *
                      100
                  )}
                  %)
                </div>
              )}

            {/* Deletion Progress Counter - Only show when stopping */}
            {isStoppingSync &&
              deletionProgress &&
              deletionProgress.status === "in_progress" && (
                <div style={{ marginTop: "8px" }}>
                  Removing events: {deletionProgress.eventsDeleted} /{" "}
                  {deletionProgress.totalEvents} events (
                  {Math.round(
                    (deletionProgress.eventsDeleted /
                      deletionProgress.totalEvents) *
                      100
                  )}
                  %)
                </div>
              )}

            {/* Error Message */}
            {statusError && (
              <div
                style={{
                  marginTop: "8px",
                  fontSize: "0.9em",
                  color: "#CF1322",
                }}
              >
                {statusError}
              </div>
            )}
          </div>
        )}
      </>
    </div>
  );
};

export default CalendarIntegration;
