import { Outlet, useLocation, NavLink } from "react-router-dom";
// import "./Layout.css";
import logo from "../img/motionair_icon_blue.svg";
import { useAuth } from "../Hooks/useAuth";

const Layout = ({ hideHeaderPaths = [] }) => {
  const { onLogout } = useAuth();
  const { pathname } = useLocation();

  return (
    <>
      {!hideHeaderPaths.includes(pathname) && (
        <>
          <nav>
            <ul className="nav-ul">
              <li>
                <img className="logo-image" src={logo} alt=""></img>
              </li>
              <NavLink
                to="/Calendar"
                className={({ isActive }) => (isActive ? "active" : "regular")}
              >
                Calendar
              </NavLink>
              <NavLink
                to="/Agenda"
                className={({ isActive }) => (isActive ? "active" : "regular")}
              >
                Agenda
              </NavLink>
              <NavLink
                to="/Todo"
                className={({ isActive }) => (isActive ? "active" : "regular")}
              >
                Todo
              </NavLink>
              <NavLink
                to="/Settings"
                className={({ isActive }) => (isActive ? "active" : "regular")}
              >
                Settings
              </NavLink>
              <div></div>
              <li className="log-out-container">
                <button className="log-out-btn" onClick={onLogout}>
                  Log-out
                </button>
              </li>
            </ul>
          </nav>
        </>
      )}
      <Outlet />
    </>
  );
};

export default Layout;
