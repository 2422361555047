import baseX from "base-x"; // For Base32 encoding

const base32Chars = "abcdefghijklmnopqrstuv123456789"; // Base32 alphabet
const base32 = baseX(base32Chars);

export const uuidToBase32Hex = (uuidString) => {
    console.log("uuidToBase32Hex")
    // Remove hyphens from UUID string
    const uuidWithoutHyphens = uuidString.replace(/-/g, '');
    // Convert hex to array of bytes
    const bytes = [];
    for (let i = 0; i < uuidWithoutHyphens.length; i += 2) {
        bytes.push(parseInt(uuidWithoutHyphens.substr(i, 2), 16));
    }
    // Encode array of bytes to base32
    const encoded = base32.encode(bytes);
    return encoded.toLowerCase();
};

export const base32HexToUUID = (base32String) => {
    console.log("base32HexToUUID")

    // Decode base32 string to array of bytes
    const bytes = base32.decode(base32String);
    // Convert array of bytes to hex
    let hexString = '';
    for (let i = 0; i < bytes.length; i++) {
        hexString += (bytes[i] < 16 ? '0' : '') + bytes[i].toString(16);
    }
    // Insert hyphens to form UUID string
    const uuidString = hexString.replace(/(.{8})(.{4})(.{4})(.{4})(.{12})/, '$1-$2-$3-$4-$5');
    return uuidString;
};
