import React, { useState } from "react";

export const Context = React.createContext();

export const UserContextProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState('no user initiated')
    const [isSignedIn, setIsSignedIn] = useState(false)

    return (
        <Context.Provider value={{ currentUser, setCurrentUser, isSignedIn, setIsSignedIn }}>
            {children}
        </Context.Provider>
    );
};