import React, { useEffect, useContext } from "react";
import { gapi } from "gapi-script";
import {httpsCallable, functions} from "../Utils/firebaseConfig";
import { AuthContext } from "../Hooks/AuthProvider";

// let gapi = window.gapi
//   let CLIENT_ID = "87148374764-91pudeet1fp6v07pe8ma3bpa4penuf7o.apps.googleusercontent.com"
//   let API_KEY = "AIzaSyDsJFds4r8zxMxMxXH1MxLMzcVTXGaaEqQ"
//   let DISCOVERY_DOCS=["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"]
//   let SCOPES = "https://www.googleapis.com/auth/calendar.events"

const calendars = {
  1: "paal@motionair.no", //PÅL A. ODDEN
  2: "reiel@motionair.no", //Reiel Lien Bruland
  3: "stian@motionair.no", //Stian Pedersen
};

export const AddGcalEvent = httpsCallable(functions, "addGcalEvent");
export const EditGcalEvent = httpsCallable(functions, "editGcalEvent");
export const DeleteGcalEvent = httpsCallable(functions, "deleteGcalEvent");



const calendarID = process.env.REACT_APP_CALENDAR_ID;
const apiKey = "AIzaSyAXs--55xSt31PjjaCkzzm0ktRqFM7saxI";
const accessToken =
  "ya29.a0Ad52N38L5Db0g-dLhnVudnqLQP7rQZk1Namxh87zDn85Yxvlay7k07dc3n3Ln8KOHkFnS_8KPbFswmdN8fg7-WvK0xMxmOACWiLbtX7f8jq0fSOkp7vfb4mm4I9bdw1V5G9ONNiAGiM319Vet3dLIVBr1ESYNDTHxgC8tgaCgYKARQSARISFQHGX2MiOY4ZLL8_ohrDMMJjYwGS8w0173";

let SCOPES =
  "https://www.googleapis.com/auth/calendar.events, https://www.googleapis.com/auth/calendar";

// export const AddGcalEvent = ({ event, accessToken, resource, calendar, user }) => {

//     if (calendars[resource] === user) {
//       resource.forEach(person => {
//         // const calendar = calendars[person];
  
//         const calendarUrl = `https://www.googleapis.com/calendar/v3/calendars/${calendar}/events`;
  
//         fetch(calendarUrl, {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${accessToken}`,
//           },
//           body: JSON.stringify(event),
//         })
//           .then(response => {
//             if (!response.ok) throw new Error(`Error: ${response.status}`);
//             return response.json();
//           })
//           .then(data => {
//             console.log("Event created:", data);
//           })
//           .catch(error => {
//             console.error("Error creating event:", error);
//           });
//       });
//     }
//   };


//   export const EditGcalEvent = ({ event, accessToken, resource, customId, user, calendar }) => {

//     console.log(resource)
//     if (calendars[resource] === user) {
//       console.log("editGcalEvent: ", event, accessToken, resource, customId, user, calendar);
      
//       const calendarUrl = `https://www.googleapis.com/calendar/v3/calendars/${calendar}/events`;
  
//       // Fetch all events to find the one with the customId
//       fetch(calendarUrl, {
//         method: "GET",
//         headers: {
//           "Authorization": `Bearer ${accessToken}`,
//           "Content-Type": "application/json",
//         },
//       })
//       .then(response => {
//         if (!response.ok) throw new Error(`Error: ${response.status}`);
//         return response.json();
//       })
//       .then(data => {
//         // Find the event with the specified customId
//         const eventToUpdate = data.items.find(event => 
//           event.extendedProperties?.private?.customId === customId
//         );
  
//         if (eventToUpdate) {
//           // Update the found event with new details
//           const eventUpdateUrl = `${calendarUrl}/${eventToUpdate.id}`;

//           console.log(eventUpdateUrl)
  
//           fetch(eventUpdateUrl, {
//             method: "PUT",
//             headers: {
//               "Content-Type": "application/json",
//               Authorization: `Bearer ${accessToken}`,
//             },
//             body: JSON.stringify({ ...eventToUpdate, ...event }), // Merging new properties with existing ones
//           })
//           .then(response => {
//             if (!response.ok) throw new Error(`Error: ${response.status}`);
//             return response.json();
//           })
//           .then(data => {
//             console.log("Event updated:", data);
//           })
//           .catch(error => {
//             console.error("Error updating event:", error);
//           });
//         } else {
//           console.error("Event with customId not found.");
//         }
//       })
//       .catch(error => {
//         console.error("Error fetching events:", error);
//       });
//     }
//   };


//   export const DeleteGcalEvent = ({ accessToken, resource, customId, user, calendar }) => {
//     if (calendars[resource] === user) {
//       console.log("deleteGcalEvent: ", accessToken, resource, customId);
      
//       const calendarUrl = `https://www.googleapis.com/calendar/v3/calendars/${calendar}/events`;
  
//       // Fetch all events to find the one with the customId
//       fetch(calendarUrl, {
//         method: "GET",
//         headers: {
//           "Authorization": `Bearer ${accessToken}`,
//           "Content-Type": "application/json",
//         },
//       })
//       .then(response => {
//         if (!response.ok) throw new Error(`Error: ${response.status}`);
//         return response.json();
//       })
//       .then(data => {
//         // Find the event with the specified customId
//         const eventToDelete = data.items.find(event => 
//           event.extendedProperties?.private?.customId === customId
//         );
  
//         if (eventToDelete) {
//           // Delete the found event
//           const eventDeleteUrl = `${calendarUrl}/${eventToDelete.id}`;
  
//           fetch(eventDeleteUrl, {
//             method: "DELETE",
//             headers: {
//               "Content-Type": "application/json",
//               Authorization: `Bearer ${accessToken}`,
//             },
//           })
//           .then(response => {
//             if (!response.ok) throw new Error(`Error: ${response.status}`);
//             console.log("Event deleted successfully");
//           })
//           .catch(error => {
//             console.error("Error deleting event:", error);
//           });
//         } else {
//           console.error("Event with customId not found.");
//         }
//       })
//       .catch(error => {
//         console.error("Error fetching events:", error);
//       });
//     }
//   };


  