import { useState, createContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, onAuthStateChanged, signInWithPopup, GoogleAuthProvider, signOut, signInWithEmailAndPassword } from '../Utils/firebaseConfig'
import { toast } from '@mobiscroll/react';

import { functions, httpsCallable, database, doc, getDoc, setDoc, onSnapshot, getDocs, collection, updateDoc, query, where } from "../Utils/firebaseConfig";

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userSettings, setUserSettings] = useState([])
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // New loading state
  const [gCalAccessToken, setGcalAccessToken] = useState(null); // New loading state
  const [gCalRefreshToken, setGcalRefreshToken] = useState(null); // New loading state
  const [userCalendars, setUserCalendars] = useState([]); // New loading state
  const [userSelectedCalendar, setUserSelectedCalendar] = useState(""); // Initialize as empty string




  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        setLoading(true);
        
        try {
          const userDoc = await getDoc(doc(database, "user-settings", currentUser.email));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setUserSettings(userData);
            
            if (userData.googleCalendarConnected) {
              const getAccessToken = httpsCallable(functions, "getNewAccessToken");
              const result = await getAccessToken();
              
              if (result.data?.access_token) {
                setGcalAccessToken(result.data.access_token);
                
                // Fetch calendars if they don't exist
                if (!userData.userCalendars || userData.userCalendars.length === 0) {
                  try {
                    const response = await fetch(
                      'https://www.googleapis.com/calendar/v3/users/me/calendarList',
                      {
                        headers: {
                          Authorization: `Bearer ${result.data.accessToken}`,
                          Accept: "application/json",
                        },
                      }
                    );
                    const calendarData = await response.json();
                    
                    if (calendarData.items) {
                      setUserCalendars(calendarData.items);
                      // Also save to Firestore
                      await setDoc(doc(database, "user-settings", currentUser.email), {
                        userCalendars: calendarData.items
                      }, { merge: true });
                    }
                  } catch (error) {
                    console.error("Error fetching calendars:", error);
                  }
                } else {
                  setUserCalendars(userData.userCalendars);
                }
              }
            }

            // Only navigate if we're on the root path
            const currentPath = window.location.pathname;
            if (currentPath === '/') {
              if (userData.startPage === 10) {
                navigate("/calendar");
              } else if (userData.startPage === 20) {
                navigate("/agenda");
              } else if (userData.startPage === 30) {
                navigate("/todo");
              } else if (userData.startPage === 40) {
                navigate("/Settings");
              } else {
                console.log("default")

                navigate("/calendar"); // Default path
              }
            }

            // Set the selected calendar if it exists
            if (userData.userSelectedCalendar) {
              setUserSelectedCalendar(userData.userSelectedCalendar);
            } else {
              setUserSelectedCalendar(""); // Ensure it's an empty string if not found
            }
          }
        } catch (error) {
          console.error("Error setting up user:", error);
        } finally {
          setLoading(false);
        }
      } else {
        const currentPath = window.location.pathname;
        setUser(null);
        setGcalAccessToken(null);
        setUserCalendars([]);
        setLoading(false);
        if (currentPath !== '/') {
          navigate("/");
        }
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  // Modify this useEffect (around line 120)
  useEffect(() => {
    if (!user?.email) return;

    const unsubscribe = onSnapshot(
      doc(database, "user-settings", user.email),
      (doc) => {
        if (doc.exists()) {
          const userData = doc.data();
          
          // Update selected calendar if changed
          if (userData.userSelectedCalendar && userData.userSelectedCalendar !== userSelectedCalendar) {
            setUserSelectedCalendar(userData.userSelectedCalendar);
          }

          // Update user settings if changed
          setUserSettings(prevSettings => {
            // Only update if there are actual changes
            if (JSON.stringify(prevSettings) === JSON.stringify(userData)) {
              return prevSettings;
            }
            return userData;
          });
          
          // Keep the existing calendar and token refresh logic
          if (userData.userCalendars) {
            setUserCalendars(userData.userCalendars);
          }
          
          if (userData.googleCalendarConnected) {
            const getAccessToken = httpsCallable(functions, "getNewAccessToken");
            getAccessToken()
              .then(result => {
                if (result.data?.accessToken) {
                  setGcalAccessToken(result.data.accessToken);
                }
              })
              .catch(error => console.error("Error refreshing token:", error));
          }
        }
      },
      (error) => {
        console.error("Error listening to user settings:", error);
      }
    );

    return () => unsubscribe();
  }, [user?.email]); // Remove userSettings and userSelectedCalendar from dependencies

  const handleLogin = async () => {
    const provider = new GoogleAuthProvider();
    provider.addScope("https://www.googleapis.com/auth/calendar");
  
    try {
      const result = await signInWithPopup(auth, provider);
      const checkUserIsEmployee = httpsCallable(functions, "checkUserIsEmployee");
  
      const res = await checkUserIsEmployee({ currentUser: result.user.email });
      if (res.data.isRegistered === false) {
        await signOut(auth);
        console.log("warning", `You are logged out!`);
        console.log("Contact site administrator to gain access.");
        setUser(null);
      } else {
        setLoading(false);
  
        checkGcalAuthToken(result.user);
        console.log("Welcome home", result.user.displayName);
  
        setUser(result.user);
        console.log("result.user.email", result.user.email)
        // Navigate based on user settings
        const userDoc = await getDoc(doc(database, "user-settings", result.user.email));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          if (userData.startPage === 10) {
            navigate("/calendar");
          } else if (userData.startPage === 20) {
            navigate("/agenda");
          } else if (userData.startPage === 30) {
            navigate("/todo");
          } else if (userData.startPage === 40) {
            navigate("/Settings");
          } else {
            navigate("/calendar"); // Default path
          }
        } else {
          navigate("/calendar"); // Default path if user settings don't exist
        }
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  async function checkGcalAuthToken(user) {
    console.log('Starting checkGcalAuthToken for user:', user.email);
    const getAccessToken = httpsCallable(functions, "getNewAccessToken");

    const userSettingsDocRef = doc(database, "user-settings", user.email);
    const userSettingsDoc = await getDoc(userSettingsDocRef);
    
    console.log('User settings doc:', userSettingsDoc.data());
    
    if (userSettingsDoc.exists() && userSettingsDoc.data().googleCalendarConnected) {
        try {
            console.log('Attempting to get new access token...');
            const result = await getAccessToken();
            console.log('Token result:', result);
            
            if (result.data && result.data.access_token) {
                console.log('Successfully got new access token');
                setGcalAccessToken(result.data.access_token);
            }
        } catch (error) {
            console.error('Detailed token error:', {
                message: error.message,
                code: error.code,
                details: error.details
            });
            setGcalAccessToken(null);
        }
    }
  }

const revokeAccess = async () => {
  try {
    const revoke = httpsCallable(functions, "revokeAppAccess");
    await revoke();
    
    // Update email-based document
    if (user) {
      await updateDoc(doc(database, "user-settings", user.email), {
        googleCalendarConnected: false
      });
      console.log(user)
      // Update UID-based document
      await updateDoc(doc(database, "user-settings", user.uid), {
        refreshToken: null
      });
    }
    
    // Clear local state
    setGcalAccessToken(null);
    setGcalRefreshToken(null);
    setUserCalendars([]);
    setUserSelectedCalendar("");
  } catch (error) {
    console.error("Error revoking access:", error);
  }
};

  const updateSelectedCalendar = async (newCalendar) => {
    if (user && newCalendar !== "") {
      const userDocRef = doc(database, "user-settings", user.email);
      try {
        // Fetch the current user document data
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          // Update the `userSelectedCalendar` field by adding newCalendar
          await updateDoc(userDocRef, {
            userSelectedCalendar: newCalendar  // Adds newCalendar without replacing the existing array
          });
        } else {
          console.warn("No such document!");
        }
      } catch (error) {
        console.error("Error updating calendar: ", error);
      }
    }
  };

  // useEffect(() => {
  //   if(userSelectedCalendar) {
  //   updateSelectedCalendar(userSelectedCalendar)
  //   }
  // }, [userSelectedCalendar])


  const handleLoginWithEmail = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      setUser(userCredential.user);
      
      // Get user settings and navigate
      const userDoc = await getDoc(doc(database, "user-settings", email));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        if (userData.startPage === 10) {
          navigate("/calendar");
        } else if (userData.startPage === 20) {
          navigate("/agenda");
        } else if (userData.startPage === 30) {
          navigate("/todo");
        } else if (userData.startPage === 40) {
          navigate("/Settings");
        } else {
          navigate("/calendar");
        }
      }
    } catch (error) {
      let message = error.message.replace('Firebase:', '');
      toast({
        message: `${message}`,
        color: 'danger'
      });
      console.error(error);
    }
  };


  const handleLogout = () => {
    console.log("LOGOUT");
    signOut(auth)
      .then(() => {
        console.warn("warning", `You are logged out!`);
        setUser(null);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // console.log('test')

  const value = {
    user,
    userSettings,
    onLogin: handleLogin,
    onLoginWithEmail: handleLoginWithEmail,
    onLogout: handleLogout,
    gCalAccessToken,
    gCalRefreshToken,
    setGcalAccessToken,
    setGcalRefreshToken,
    userCalendars,
    setUserCalendars,
    userSelectedCalendar,
    setUserSelectedCalendar,
    revokeAccess
  };

  return <AuthContext.Provider value={value}>
    {children}
  </AuthContext.Provider>;
};

