import { GoogleOAuthProvider } from "@react-oauth/google";
import Settings from "../Components/Settings/Settings";

// import "./Settings.css";

const SettingsView = () => {
  return (
    <div>
      <GoogleOAuthProvider clientId="323150633316-m43g3oj3qkcvlkookie1qji41o2oat0b.apps.googleusercontent.com">
        <Settings />
      </GoogleOAuthProvider>
    </div>
  );
};

export default SettingsView;
