import { useEffect, useRef, useState, useMemo } from "react";
import { calculateOffScreenPosition } from "../Utils/cursorCalculations";

export default function CursorIndicator({ id, x, y, name, color }) {
  const rCursor = useRef(null);
  const [dimensions, setDimensions] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  // console.log('CursorIndicator color:', color)

  // Only update dimensions on mount and resize
  useEffect(() => {
    const updateDimensions = () => {
      const container = document.querySelector('.mbsc-timeline-grid-scroll');
      const resourceColumn = document.querySelector('.mbsc-timeline-resource-col');
      
      if (container && resourceColumn) {
        setDimensions({
          width: container.clientWidth,
          height: container.clientHeight,
          resourceColumnWidth: resourceColumn.clientWidth
        });
      }
    };
    
    updateDimensions();
    const resizeObserver = new ResizeObserver(updateDimensions);
    const container = document.querySelector('.mbsc-timeline-grid-scroll');
    if (container) {
      resizeObserver.observe(container);
    }
    
    return () => resizeObserver.disconnect();
  }, []);

  // Memoize position calculations
  const { position, fixedPosition } = useMemo(() => {
    if (!dimensions) return { position: { isOffScreen: false }, fixedPosition: { x: 0, y: 0 } };

    const pos = calculateOffScreenPosition(x, y, dimensions.width, dimensions.height, dimensions.resourceColumnWidth);
    
    let fixed = { x: pos.x, y: pos.y };
    if (pos.isOffScreen) {
      switch(pos.angle) {
        case 0: fixed = { x: x, y: pos.y }; break;
        case 90: fixed = { x: pos.x, y: y }; break;
        case 180: fixed = { x: x, y: pos.y }; break;
        case 270: fixed = { x: pos.x, y: y }; break;
      }
    }

    return { position: pos, fixedPosition: fixed };
  }, [x, y, dimensions]);

  // Handle visibility changes
  useEffect(() => {
    const cursor = rCursor.current;
    if (!cursor) return;

    if (position.isOffScreen) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [position.isOffScreen]);

  const convertHexToRGBA = (hexCode, opacity = 1) => {
    let hex = hexCode.replace('#', '');

    if (hex.length === 3) {
      hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
    }
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    /* Backward compatibility for whole number based opacity values. */
    if (opacity > 1 && opacity <= 100) {
      opacity = opacity / 100;
    }

    return `rgba(${r},${g},${b},${opacity})`;
  };

  // Helper function to get letter position
  const getLetterStyle = (angle) => {
    const baseStyle = {
      position: 'absolute',
      color: 'white',
      fontSize: '11px',
      fontWeight: 'bold',
      transform: `rotate(${getLetterRotation(angle)}deg)`,
      transition: 'transform 0.15s ease-out, left 0.15s ease-out',
      transformOrigin: 'center'
    };

    switch(angle) {
      case 90:  // Right side
        return { ...baseStyle, left: '-7px' };
      case 270: // Left side
        return { ...baseStyle, left: '-3px' };
      default:
        return baseStyle;
    }
  };

  // Helper function to get arrow position
  const getArrowStyle = (angle) => {
    const baseStyle = {
      position: 'absolute',
      transform: `rotate(${angle + 135}deg)`,
      transition: 'transform 0.15s ease-out, left 0.15s ease-out',
      transformOrigin: 'center',
      width: '24px',
      height: '24px'
    };

    switch(angle) {
      case 90:  // Right side
        return { ...baseStyle, left: '-20px' };
      case 270: // Left side
        return { ...baseStyle, left: '-6px' };
      default:
        return baseStyle;
    }
  };

  // Helper function to calculate letter rotation
  const getLetterRotation = (angle) => {
    switch(angle) {
      case 0: return 0;    // Top
      case 90: return 0;   // Right
      case 180: return 0;  // Bottom
      case 270: return 0;  // Left
      default: return 0;
    }
  };

  if (!dimensions) return null;

  return (
    <div
      ref={rCursor}
      style={{
        position: "fixed",
        left: 0,
        top: 0,
        transform: `translate3d(${fixedPosition.x}px, ${fixedPosition.y + 20}px, 0)`,
        opacity: isVisible ? 1 : 0,
        zIndex: 1000,
        willChange: 'opacity',
        pointerEvents: 'none',
        transition: 'opacity 0.3s ease-out, transform 0.15s ease-out, left 0.15s ease-out'
      }}
    >
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative'
      }}>
        <svg
          style={getArrowStyle(position.angle)}
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 12H9.17157C10.9534 12 11.8457 9.84572 10.5858 8.58579L3.41421 1.41421C2.15428 0.154281 0 1.04662 0 2.82843V10C0 11.1046 0.89543 12 2 12Z"
            fill={color?.dim ? convertHexToRGBA(color.color, 0.5) : "rgb(255,255,255)"}
          />
        </svg>
        <span style={getLetterStyle(position.angle)}>
          {name.charAt(0).toUpperCase()}
        </span>
      </div>
    </div>
  );
}