import { useEffect, useState } from "react";
import { useAuth } from "../Hooks/useAuth";

import logo from "../img/logo.png";

// import "./LogIn.css";

const Loading = () => {
  const { onLogin } = useAuth();
  const [loginWithEmail, setloginWithEmail] = useState(false);

  useEffect(() => {

  })


  return (
    <div>
      <img className="welcome-image" alt="Logo" src={logo}></img>
    </div>
  );
};

export default Loading;
